/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from 'react-toastify'

import { axiosPrivate } from '@/settings'

import {
	IChangeEmail,
	IChangePassword,
	IChangePhone,
	ICreateAdmin,
	ICreateUser,
	IEmployees,
	IUser,
} from '@/types'

const getAdminUrl = (url: string): string => `/users/${url}`

export const UserService = {
	async createAdminTemp(body: ICreateAdmin): Promise<any> {
		try {
			const { data } = await axiosPrivate.post<any>(
				getAdminUrl(`get_info`),
				body
			)

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async ban(id: string, update: () => void, close?: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl(`ban_user?UserId=${id}`))
			toast.success('Пользователь заблокирован')
			update()
			close && close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async unBan(
		id: string,
		update: () => void,
		close?: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl(`unban_user?UserId=${id}`))
			toast.success('Пользователь разблокирован')
			update()
			close && close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async createUser(body: ICreateUser, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post(getAdminUrl('create_user'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},

	async createManager(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_manager'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},

	async createMarketer(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_marketer'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},

	async createGuard(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_guard'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},

	async createDispatcher(body: ICreateAdmin, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAdminUrl('create_dispatcher'), body)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},
	async createCounterpart(
		body: Omit<
			ICreateUser,
			'cpName' | 'cpLegalName' | 'cpITN' | 'cpEmergencyPhone'
		>,
		close: () => void
	): Promise<void> {
		try {
			await axiosPrivate.post<any>(
				getAdminUrl('create_user_without_company'),
				body
			)
			toast.success('Пользователь создан')
			close()
		} catch (e: any) {
			switch (e.response.data) {
				case `Email ${body.email} already taken`:
					toast.error('Такая почта уже существует')
					return e
				default:
					toast.error(e.response)
					return e
			}
		}
	},
	async deleteUser(
		id: string,
		update: () => void,
		close: () => void
	): Promise<void> {
		try {
			await axiosPrivate.delete<any>(getAdminUrl(`delete_user`), {
				data: id,
				headers: {
					'Content-Type': 'application/json',
				},
			})
			toast.success('Пользователь удален')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserAll(): Promise<IUser[]> {
		try {
			const { data } = await axiosPrivate.get<IUser[]>(getAdminUrl('all'))

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserAllAdmins(): Promise<IUser[]> {
		try {
			const { data } = await axiosPrivate.get<IUser[]>(
				getAdminUrl('all_admins')
			)

			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getInfo(): Promise<IUser> {
		try {
			const { data } = await axiosPrivate.get<IUser>(getAdminUrl(`get_info`))
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserCounterpartyView({
		UserId,
		CounterpartyId,
	}: {
		UserId?: string
		CounterpartyId?: string
	}): Promise<IEmployees> {
		try {
			let params = new URLSearchParams()
			UserId !== undefined ? params.append('UserId', UserId) : null
			CounterpartyId !== undefined
				? params.append('CounterpartyId', CounterpartyId)
				: null
			const { data } = await axiosPrivate.get<IEmployees>(
				getAdminUrl(`get_user_counterparty_view?${params}`)
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changePassword(
		body: IChangePassword,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_password'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changeEmail(
		body: IChangeEmail,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_email'), body)

			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async changePhone(
		body: IChangePhone,
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_phone'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async changeInfoAdmin(
		body: {
			userId: string
			phone: string
			email: string
			name: string
			counterpartyName?: string
			itn?: string
		},
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getAdminUrl('change_user_info'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
	async setHistoryMessage({
		userId,
		subject,
		body,
		attachments,
		close,
	}: {
		userId: string
		subject: string
		body: string
		attachments: File[]
		close: () => void
	}) {
		try {
			const postBody = new FormData()
			postBody.append('UserId', userId)
			postBody.append('Subject', subject)
			postBody.append('Body', body)
			for (let file of attachments) postBody.append('Attachments', file)

			await axiosPrivate.post(getAdminUrl('send_message'), postBody)

			toast.success('Сообщение отправлено')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},
	async getHistoryMessage({ userId }: { userId: string }) {
		try {
			const { data } = await axiosPrivate.get(getAdminUrl('get_messages'), {
				params: {
					UserId: userId,
				},
			})
			return data
		} catch (e: any) {
			console.log(e)

			toast.error(e.message)
			return e.message
		}
	},
}
