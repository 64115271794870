import cn from 'clsx'
import { useAuth } from 'hooks/useAuth'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router'

import { Button, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	close: () => void
}> = ({ close }) => {
	const { role } = useAuth()
	const navigate = useNavigate()

	const ButtonBlock = () => {
		switch (role) {
			case 'Admin':
			case 'Manager':
				return (
					<>
						<Button
							appearance="white"
							onClick={() => navigate('/request/new/import-export')}
						>
							Новая заявка на внос/вынос ТМЦ
						</Button>
						<Button
							appearance="white"
							onClick={() => navigate('/request/new/work')}
						>
							Новая заявка на выполнение работ
						</Button>
						<Button
							appearance="white"
							onClick={() => navigate('/request/new/promo')}
						>
							Новая заявка на промо, фото/видео съемку
						</Button>
						{/* <Button
							appearance="white"
							onClick={() => navigate('/request/new/pass')}
						>
							Новая заявка на пропуск сотрудника
						</Button> */}
					</>
				)
			case 'Marketer':
				return (
					<Button
						appearance="white"
						onClick={() => navigate('/request/new/promo')}
					>
						Новая заявка на промо, фото/видео съемку
					</Button>
				)
			default:
				break
		}
	}

	return (
		<div className={cn(styles.modal, 'w-[300px]')}>
			<div className={styles.header}>
				<SubHeading title="Создание новой заявки" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<ButtonBlock />
			</div>
		</div>
	)
}
