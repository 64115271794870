import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'

import { Button, FieldInput, SubHeading } from '@/components'

import { UserService } from '@/services'

import { IChangePhone } from '@/types'

import styles from './Modal.module.scss'

export const ChangeUserPhoneModal: FC<{
	close: () => void
	update: () => void
}> = ({ close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IChangePhone>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IChangePhone> = async (body) =>
		await UserService.changePhone(body, close, update)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить номер" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Пароль"
					type="password"
					{...register('password', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.password}
				/>
				<FieldInput
					placeholder="Номер телефона"
					type="tel"
					{...register('phoneNumber', {
						required: 'Поле обязательно к заполнению!',
						pattern: {
							value: /(\+7|7|8)+([0-9]){10}/gm, //eslint-disable-line
							message: 'Формат номера телефона +79123456789',
						},
					})}
					error={errors.phoneNumber}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
