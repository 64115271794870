import cn from 'clsx'
import { ForwardedRef, forwardRef, memo } from 'react'

import styles from './Input.module.scss'
import { InputProps } from './Input.props'

export const Input = memo(
	forwardRef(
		(
			{ className, ...props }: InputProps,
			ref: ForwardedRef<HTMLInputElement>
		): JSX.Element => {
			return (
				<input className={cn(styles.input, className)} ref={ref} {...props} />
			)
		}
	)
)
