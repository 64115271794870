import type { CalendarProps } from 'antd'
import { Badge, Calendar, ConfigProvider } from 'antd'
import ru_RU from 'antd/lib/locale/ru_RU'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { nanoid } from 'nanoid'
import { FC, useEffect, useState } from 'react'
import { RequestsService } from 'services/Requests.service'

export const CalendarCustom: FC<{
	className?: string
}> = ({ className = '' }) => {
	const [data, setData] = useState([])
	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		const data = await RequestsService.getPromoDates()
		setData(data)
	}

	const dateCellRender = (value: Dayjs) => {
		const stringValue = value.format('DD/MM/YYYY')

		const listData = data?.filter(
			({ startDate }) =>
				('0' + new Date(startDate).getDate()).slice(-2) +
					'/' +
					('0' + (new Date(startDate).getMonth() + 1)).slice(-2) +
					'/' +
					new Date(startDate).getFullYear() ===
				stringValue
		)

		return (
			<ul className="events">
				{listData.map((item) => (
					<li key={nanoid()}>
						<Badge
							status={item.isHasCounterparty ? 'success' : 'warning'}
							text={item.promoType.name}
						/>
					</li>
				))}
			</ul>
		)
	}
	dayjs.locale('ru')

	const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
		if (info.type === 'date') return dateCellRender(current)
		return info.originNode
	}

	return (
		<div className="max-w-[720px] h-[550px]">
			<ConfigProvider locale={ru_RU}>
				<Calendar
					cellRender={cellRender}
					className={className}
					fullscreen={true}
				/>
			</ConfigProvider>
		</div>
	)
}
