import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'
import { IUser } from 'types/User.type'

import { Button, SubHeading } from '@/components'

import { TypeModal } from '../List'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	user: IUser
	open: (type: TypeModal) => void
	close: () => void
	update: () => void
}> = ({ user, open, close, update }) => {
	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Управление" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<Button onClick={() => open('info')}>Сменить информацию</Button>
				<Button onClick={() => open('password')}>Сменить пароль</Button>
				<Button onClick={() => open('message')}>Отправить уведомление</Button>
				<Button onClick={() => open('history')}>История уведомлений</Button>
				{user.isBanned ? (
					<Button
						onClick={async () =>
							await UserService.unBan(user.id, update, close)
						}
					>
						Разбанить
					</Button>
				) : (
					<Button
						onClick={async () => await UserService.ban(user.id, update, close)}
						appearance="red"
					>
						Забанить
					</Button>
				)}
				<Button
					onClick={async () =>
						await UserService.deleteUser(user.id, update, close)
					}
					appearance="red"
				>
					Удалить
				</Button>
			</div>
		</div>
	)
}
