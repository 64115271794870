import cn from 'clsx'
import { FC, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'

import { SubHeading } from '@/components'

import { IHistoryMessage } from '@/types'

import styles from './Modal.module.scss'

export const HistoryMessageModal: FC<{
	id: string
	close: () => void
	update: () => void
}> = ({ id, close }) => {
	const [history, setHistory] = useState<IHistoryMessage[]>([])

	useEffect(() => {
		fetch()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const fetch = async () => {
		const data = await UserService.getHistoryMessage({ userId: id })
		setHistory(data)
	}

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="История уведомлений" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn('!flex-col', styles.body)}>
				{history.length ? (
					history.map((item) => (
						<div className="my-2" key={item.id}>
							<p>
								<b>Тема:</b> <span>{item.subject}</span>
							</p>
							<p>
								<b>Сообщение:</b> <span>{item.body}</span>
							</p>
							<p>
								<b>Файлы:</b> <span>{item.attachmentsNames}</span>
							</p>
						</div>
					))
				) : (
					<p>Данных нет</p>
				)}
			</div>
		</div>
	)
}
