import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'

import { CalendarCustom, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const CalendarModal: FC<{
	close: () => void
}> = ({ close }) => {
	return (
		<div className={cn(styles.modal)}>
			<div className={styles.header}>
				<SubHeading title="Календарь событий" />
				<MdClose size={20} onClick={close} />
			</div>
			<div>
				<CalendarCustom />
			</div>
		</div>
	)
}
