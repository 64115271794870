import { useEffect, useState } from 'react'
import { UserService } from 'services/user.service'

import { UserButtons, UserInfo } from '@/components'
import { Advertisements } from '@/components'

import { useAuth } from '@/hooks'

import styles from './User.module.scss'

export const User = (): JSX.Element => {
	const { role } = useAuth()
	const [isUr, setIsUr] = useState<boolean>(false)

	useEffect(() => {
		if (role === 'User') fetch()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const fetch = async () => {
		const data = await UserService.getInfo()
		if (data.counterparty === null) {
			setIsUr(true)
		}
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.info}>
				<UserButtons role={isUr ? 'Ur' : role} />
				<UserInfo role={isUr ? 'Ur' : role} />
			</div>
			<Advertisements />
		</div>
	)
}

export default User
