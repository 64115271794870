import { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { Loader, SubHeading, Table, Title } from '@/components'

import { UserService } from '@/services'

import { modalStyle } from '@/settings'

import { IUser } from '@/types'

import styles from './List.module.scss'
import {
	AddMessageModal,
	ChangeInfoModal,
	ChangePasswordModal,
	ChoiceModal,
	HistoryMessageModal,
} from './modal'
import { userColumns } from './table.config'

export type TypeModal = 'password' | 'message' | 'history' | 'choice' | 'info'

export const AdministrationList = () => {
	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [users, setUsers] = useState<IUser[]>([])
	const [type, setType] = useState<TypeModal>()
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [userProps, setUserProps] = useState<IUser>()

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const data = await UserService.getUserAllAdmins()
		setUsers(data)
		setIsLoad(false)
	}

	const openModal = (type: TypeModal, user?: IUser) => {
		setType(type)
		if (type === 'choice') setUserProps(user)
		setIsOpen(true)
	}

	const closeModal = () => {
		setIsOpen(false)
	}

	const Component = () => {
		switch (type) {
			case 'choice':
				return (
					<ChoiceModal
						open={openModal}
						close={closeModal}
						user={userProps}
						update={fetch}
					/>
				)
			case 'info':
				return (
					<ChangeInfoModal
						id={userProps.id}
						close={closeModal}
						update={fetch}
					/>
				)
			case 'password':
				return (
					<ChangePasswordModal
						email={userProps.email}
						close={closeModal}
						update={fetch}
					/>
				)
			case 'history':
				return (
					<HistoryMessageModal
						id={userProps.id}
						close={closeModal}
						update={fetch}
					/>
				)
			case 'message':
				return <AddMessageModal id={userProps.id} close={closeModal} />
			default:
				return <></>
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Title title="Список пользователей" />
				{users?.length ? (
					<div className={styles.table}>
						<Table
							dataProps={users.map((item) => {
								return {
									...item,
									isBannedStr: item.isBanned ? 'Заблокирован' : 'Активен',
									role:
										item.role === 'User'
											? 'Юридическое лицо'
											: item.role === 'Manager'
												? 'Менеджер'
												: item.role === 'Guard'
													? 'Охранник'
													: item.role === 'Dispatcher'
														? 'Диспетчер'
														: item.role === 'Marketer'
															? 'Маркетолог'
															: item.role === 'Admin'
																? 'Администратор'
																: '-',
								}
							})}
							columns={userColumns(openModal)}
						/>
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<Component />
			</Modal>
		</>
	)
}
