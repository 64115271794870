import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from './useAuth'

export const useCheckToken = () => {
	const { setRole, setToken, serRefreshToken } = useAuth()
	const { pathname } = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		const tokenCookies = Cookies.get('token')
		const refreshToken = Cookies.get('refreshToken')
		const roleCookies = Cookies.get('role')

		if (!tokenCookies && pathname && refreshToken === '/auth') {
			navigate('/')
		}

		if (tokenCookies) setToken && setToken(tokenCookies)
		if (refreshToken) serRefreshToken && serRefreshToken(refreshToken)
		if (roleCookies) setRole && setRole(roleCookies)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])
}
