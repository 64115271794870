import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'

import { Button, FieldInput, SubHeading, Textarea } from '@/components'

import styles from './Modal.module.scss'

interface IForm {
	attachments: File[]
	subject: string
	body: string
}

export const AddMessageModal: FC<{
	id: string
	close: () => void
}> = ({ id, close }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IForm>({
		mode: 'all',
	})

	const onSubmit: SubmitHandler<IForm> = async (body) =>
		await UserService.setHistoryMessage({
			userId: id,
			...body,
			close,
		})

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Отправить уведомление" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Тема"
					required
					{...register('subject', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.subject}
				/>
				<Textarea
					placeholder="Сообщение"
					required
					{...register('body', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.body}
				/>
				<FieldInput
					type="file"
					placeholder="Файл"
					multiple
					{...register('attachments')}
				/>
				<Button>Отправить</Button>
			</form>
		</div>
	)
}
