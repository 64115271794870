import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'

import { Button, FieldInput, SubHeading } from '@/components'

import { UserService } from '@/services'

import { IChangePassword } from '@/types'

import styles from './Modal.module.scss'

export const ChangeUserPasswordModal: FC<{
	close: () => void
	update: () => void
}> = ({ close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IChangePassword>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IChangePassword> = async (body) =>
		await UserService.changePassword(body, close, update)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить пароль" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Старый пароль"
					type="password"
					{...register('oldPassword', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.oldPassword}
				/>
				<FieldInput
					placeholder="Новый пароль"
					type="password"
					{...register('newPassword', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.oldPassword}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
