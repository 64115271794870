import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import { IDictionaries, ISelect } from '../types/Dictionaries.type'

const getDictionariesUrl = (url: string) => `/dictionaries/${url}`

export const DictionariesService = {
	async passageForms(): Promise<IDictionaries[]> {
		try {
			const { data } = await axiosPrivate.get<IDictionaries[]>(
				getDictionariesUrl('passage_forms')
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async passagePurposes(): Promise<IDictionaries[]> {
		try {
			const { data } = await axiosPrivate.get<IDictionaries[]>(
				getDictionariesUrl('passage_purposes')
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async promoTypes(): Promise<ISelect[]> {
		try {
			const { data } = await axiosPrivate.get<IDictionaries[]>(
				getDictionariesUrl('promo_types')
			)
			return data
				.map((item) => {
					return { label: item.name, value: item.id }
				})
				.sort((a, b) => {
					if (a.label.toLowerCase() < b.label.toLowerCase()) {
						return -1
					}
					if (a.label.toLowerCase() > b.label.toLowerCase()) {
						return 1
					}
					return 0
				})
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async requestStatuses(): Promise<IDictionaries[]> {
		try {
			const { data } = await axiosPrivate.get<IDictionaries[]>(
				getDictionariesUrl('request_statuses')
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async workTypes(): Promise<ISelect[]> {
		try {
			const { data } = await axiosPrivate.get<IDictionaries[]>(
				getDictionariesUrl('work_types')
			)
			return data
				.map((item) => {
					return { label: item.name, value: item.id }
				})
				.sort((a, b) => {
					if (a.label.toLowerCase() < b.label.toLowerCase()) {
						return -1
					}
					if (a.label.toLowerCase() > b.label.toLowerCase()) {
						return 1
					}
					return 0
				})
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
