import { CalendarModal } from 'pages/Request/New/modal'
import { FC, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components'

import { modalStyle } from '@/settings'

import { ChoiceModal } from './ChoiceModa'
import styles from './UserButtons.module.scss'

interface IUserButtons {
	role: string
}

type TTypeModal = 'сhoice' | 'calendar' | ''

export const UserButtons: FC<IUserButtons> = ({ role }): JSX.Element => {
	const navigate = useNavigate()
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [typeModal, setTypeModal] = useState<TTypeModal>('')

	const navTo = (url: string) => {
		return () => {
			navigate(url)
		}
	}

	switch (role) {
		case 'Ur':
			return (
				<div className={styles.btnMain}>
					<Button appearance="white" onClick={navTo('/request/new/promo')}>
						Новая заявка на промо, фото/видео съемку
					</Button>
					<Button appearance="white" onClick={navTo('/request/list')}>
						Мои заявки
					</Button>
				</div>
			)
		case 'User':
			return (
				<div className={styles.btnMain}>
					<Button
						appearance="white"
						onClick={navTo('/request/new/import-export')}
					>
						Новая заявка на внос/вынос ТМЦ
					</Button>
					<Button appearance="white" onClick={navTo('/request/new/work')}>
						Новая заявка на выполнение работ
					</Button>
					<Button appearance="white" onClick={navTo('/request/new/promo')}>
						Новая заявка на промо, фото/видео съемку
					</Button>
					<Button appearance="white" onClick={navTo('/request/list')}>
						Мои заявки
					</Button>
					<Button appearance="white" onClick={navTo('/employees')}>
						Сотрудники
					</Button>
				</div>
			)
		case 'Marketer':
			return (
				<>
					<div className={styles.btnMain}>
						<Button appearance="white" onClick={navTo('/request/new/promo')}>
							Новая заявка на промо, фото/видео съемку
						</Button>
						<Button appearance="white" onClick={navTo('/request/new/list')}>
							Новые заявки
						</Button>
						<Button appearance="white" onClick={navTo('/request/active/list')}>
							Действующие заявки
						</Button>
						<Button appearance="white" onClick={navTo('/request/archive/list')}>
							Архив заявок
						</Button>
						<Button
							appearance="white"
							onClick={() => {
								setIsOpen(true)
								setTypeModal('calendar')
							}}
						>
							Календарь событий
						</Button>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={() => setIsOpen(false)}
						ariaHideApp={false}
						style={modalStyle}
					>
						{typeModal === 'сhoice' ? (
							<ChoiceModal close={() => setIsOpen(false)} />
						) : typeModal === 'calendar' ? (
							<CalendarModal close={() => setIsOpen(false)} />
						) : null}
					</Modal>
				</>
			)
		case 'Guard':
			return (
				<div className={styles.btnMain}>
					<Button appearance="white" onClick={navTo('/request/active/list')}>
						Заявки
					</Button>
					<Button appearance="white" onClick={navTo('/request/archive/list')}>
						Архив заявок
					</Button>
					<Button appearance="white" onClick={navTo('/shop/list')}>
						Список магазинов
					</Button>
				</div>
			)
		case 'Dispatcher':
			return (
				<div className={styles.btnMain}>
					<Button appearance="white" onClick={navTo('/request/active/list')}>
						Заявки
					</Button>
				</div>
			)
		case 'Manager':
		case 'Admin':
			return (
				<>
					<div className={styles.btnMain}>
						<Button
							appearance="white"
							onClick={() => {
								setIsOpen(true)
								setTypeModal('сhoice')
							}}
						>
							Создать новую заявку
						</Button>
						<Button appearance="white" onClick={navTo('/request/new/list')}>
							Новые заявки
						</Button>
						<Button appearance="white" onClick={navTo('/request/active/list')}>
							Действующие заявки
						</Button>
						<Button appearance="white" onClick={navTo('/request/archive/list')}>
							Архив заявок
						</Button>
						<Button appearance="white" onClick={navTo('/shop/list')}>
							Список магазинов
						</Button>
						<Button appearance="white" onClick={navTo('/announcement/list')}>
							Список объявлений
						</Button>
						<Button appearance="white" onClick={navTo('/announcement/add')}>
							Добавить объявление
						</Button>
						<Button appearance="white" onClick={navTo('/administration/list')}>
							Список пользователей
						</Button>
						<Button
							appearance="white"
							onClick={() => {
								setIsOpen(true)
								setTypeModal('calendar')
							}}
						>
							Календарь событий
						</Button>
					</div>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={() => setIsOpen(false)}
						ariaHideApp={false}
						style={modalStyle}
					>
						{typeModal === 'сhoice' ? (
							<ChoiceModal close={() => setIsOpen(false)} />
						) : typeModal === 'calendar' ? (
							<CalendarModal close={() => setIsOpen(false)} />
						) : null}
					</Modal>
				</>
			)
		default:
			return <></>
	}
}
