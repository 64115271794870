import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from '@/components'

import { IUser } from '@/types'

import { TypeModal } from './List'
import styles from './List.module.scss'

const columnHelperShops = createColumnHelper<IUser & { btns?: any }>()

export const shopsColumns = (
	open: ({ shop, type }: { shop?: IUser; type: TypeModal }) => void
) => [
	columnHelperShops.accessor('counterparty.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Название магазина</span>,
	}),
	columnHelperShops.accessor('counterparty.legalName', {
		size: 'auto' as unknown as number,
		header: () => <span>Юридическое лицо</span>,
	}),
	columnHelperShops.accessor('email', {
		size: 'auto' as unknown as number,
		header: () => <span>Почта</span>,
		cell: ({ row }) => (
			<Link to={`mailto:${row.original.email}`}>{row.original.email}</Link>
		),
	}),
	columnHelperShops.accessor('phoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Телефон</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.phoneNumber}`}>
				{row.original.phoneNumber}
			</Link>
		),
	}),
	columnHelperShops.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => open({ shop: row.original, type: 'choice' })}>
					Подробнее
				</Button>
			</div>
		),
	}),
]
