import cn from 'clsx'
import { useAuth } from 'hooks/useAuth'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

import { Button, SubHeading } from '@/components'

import { UserService } from '@/services'

import { IUser } from '@/types'

import { TypeModal } from '../List'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	shop: IUser
	open: ({ shop, type }: { shop?: IUser; type: TypeModal }) => void
	close: () => void
	update: () => void
}> = ({ shop, open, close, update }) => {
	const navigate = useNavigate()
	const { role } = useAuth()

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Управление" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<Button
					onClick={() => navigate(`/shop/${shop.counterparty.name}/requests`)}
				>
					Заявки
				</Button>
				<Button
					onClick={() =>
						navigate(`/shop/${shop.counterparty.id}/employees`, {
							state: {
								shopName: shop.counterparty.name,
							},
						})
					}
				>
					Сотрудники
				</Button>
				{role !== 'Guard' && (
					<>
						<Button onClick={() => open({ type: 'info' })}>
							Сменить информацию
						</Button>
						<Button onClick={() => open({ shop, type: 'password' })}>
							Сменить пароль
						</Button>
						<Button onClick={() => open({ type: 'message' })}>
							Отправить уведомление
						</Button>
						<Button onClick={() => open({ type: 'history' })}>
							История уведомлений
						</Button>
						{shop.isBanned && role === 'Admin' ? (
							<Button onClick={async () => UserService.unBan(shop.id, update)}>
								Разбанить
							</Button>
						) : (
							<Button
								appearance="red"
								onClick={async () => UserService.ban(shop.id, update)}
							>
								Забанить
							</Button>
						)}
						{role === 'Admin' && (
							<Button
								appearance="red"
								onClick={async () =>
									UserService.deleteUser(shop.id, update, close)
								}
							>
								Удалить
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	)
}
