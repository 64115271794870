import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SubHeading, Table, Title } from '@/components'

import { PermitsService, RequestsService } from '@/services'

import { IInfo, IPermit } from '@/types'

import styles from './Pass.module.scss'
import { columnPass, requestColumns } from './table.config'

export const Pass = (): JSX.Element => {
	const { role } = useAuth()
	const navigate = useNavigate()

	const [pass, setPass] = useState<IPermit[]>()
	const [req, setReq] = useState<IInfo[]>()

	useEffect(() => {
		fetch()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const fetch = async () => {
		if (role === 'Guard') {
			const data = await RequestsService.getAll({
				IsApproved: true,
				RequestTypes: ['Заявка на пропуск'],
			})

			setReq(data)
		} else {
			const data = await PermitsService.getAll({})
			setPass(data)
		}
	}

	return (
		<>
			<Title title="Пропуска" />
			<div className={styles.wrapper}>
				{pass?.length || req?.length ? (
					<div className={styles.table}>
						<Table
							dataProps={
								role !== 'Guard'
									? pass.map((item) => {
											return {
												...item,
												isActive: item.isActive ? 'Получен' : 'Аннулирован',
												guardComment: item.guardComment
													? item.guardComment
													: '-',
												renterComment: item.renterComment
													? item.renterComment
													: '-',
											}
										})
									: req.map((item) => {
											return {
												...item,
												guardComment: item.guardComment
													? item.guardComment
													: '-',
											}
										})
							}
							columns={
								role !== 'Guard'
									? columnPass(role, fetch)
									: requestColumns(navigate)
							}
							colVisibility={{
								btns: role === 'Admin' || role === 'Manager',
								guardComment:
									role === 'Admin' || role === 'Manager' || role === 'Guard',
								renterComment:
									role === 'Admin' || role === 'Manager' || role === 'User',
							}}
						/>
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
		</>
	)
}
