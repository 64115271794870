import cn from 'clsx'
import { format } from 'date-fns'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Advertisements,
	Button,
	FieldInput,
	FieldSelect,
	Loader,
	Textarea,
	Title,
} from '@/components'

import { useAuth } from '@/hooks'

import {
	DictionariesService,
	EmployeesService,
	RequestsService,
	UserService,
} from '@/services'

import { isDisable, modalStyle } from '@/settings'

import { IAddPromo, ISelect, IUser } from '@/types'

import styles from './Form.module.scss'
import { CalendarModal } from './modal'
import { CommentModal } from './modal/CommentModal'

type TAddPromoForm = IAddPromo & {
	counterpartyId?: string
	promoTypeId: ISelect
}

type TTypeModal = 'comment' | 'calendar' | ''

const promoTypeIdConnector = {
	'9d3d578f-78c2-480b-973a-d7535df3c608': 30,
	'94de4ccd-e5d3-440f-8481-10f77e22fb4f': 7,
	'1e60e736-667a-42eb-9d10-800fdc4c7744': 7,
}

export const RequestNewPromo = (): JSX.Element => {
	const navigate = useNavigate()
	const { role } = useAuth()
	const { id } = useParams()
	const { state } = useLocation()

	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [types, setTypes] = useState<ISelect[]>([])
	const [typeModal, setTypeModal] = useState<TTypeModal>('')
	const [userInfo, setUserInfo] = useState<IUser>()

	const [users, setUsers] = useState<ISelect[]>([])
	const [counterparty, setCounterparty] = useState<ISelect[]>()

	const fetch = async (id?: string) => {
		setIsLoad(true)

		const types = await DictionariesService.promoTypes()
		setTypes(types)

		if (role === 'User') {
			const infoUser = await UserService.getInfo()
			setUserInfo(infoUser)
			const users = await EmployeesService.getUser()
			setUsers(
				users?.map((item) => {
					return {
						label: item.name,
						value: item.id,
					}
				})
			)
		} else if (role === 'Admin' || role === 'Manager' || role === 'Marketer') {
			const data = await UserService.getUserAll()
			setCounterparty(
				data
					.filter((item) => item.role === 'User')
					.map((item) => {
						return {
							label: item?.counterparty
								? item?.counterparty.name
								: item.userName,
							value: item?.counterparty ? item?.counterparty.id : item.id,
						}
					})
			)
			if (state?.req) {
				const users = await UserService.getUserCounterpartyView({
					CounterpartyId: state?.req?.counterparty?.id,
				})
				setUsers(
					users?.employees.map((item) => {
						return {
							label: item.name,
							value: item.id,
						}
					})
				)
			}
		} else {
			const users = await UserService.getUserCounterpartyView({ UserId: id })
			setUsers(
				users?.employees?.map((item: any) => {
					return {
						label: item.name ? item.name : item.userName,
						value: item.id,
					}
				})
			)
		}
		setIsLoad(false)
	}

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<TAddPromoForm>({
		mode: 'onChange',
		defaultValues: async () => {
			if (id) {
				const data = await RequestsService.getInfo(id)
				!isDisable(state?.req?.status, role)
					? fetch(data?.requestCreator?.id)
					: setIsLoad(false)

				return {
					...data,
					promotersMainContactId: {
						label: data?.promotersMainContact?.name,
						value: data?.promotersMainContact?.id,
					},
					employeesIdList: data?.employeesList.map((item) => {
						return { label: item.name, value: item.id }
					}),
					promoTypeId: {
						label: data?.promoType?.name,
						value: data?.promoType?.id,
					},
					counterpartyId: {
						label: data?.counterparty?.name,
						value: data?.counterparty?.id,
					},
					comment: data?.comment,
				}
			} else {
				fetch()
			}
		},
	})

	const startDateWatch = watch('startDate')
	const counterpartyIdWatch = watch('counterpartyId')
	const guardCommentWatch = watch('guardComment')
	const renterCommentWatch = watch('renterComment')
	const promoTypeIdWatch = watch('promoTypeId')

	const onSubmit: SubmitHandler<any> = async (body) => {
		if (state?.req?.status?.name === 'На рассмотрении') {
			RequestsService.editComment({
				RequestId: id,
				comment: body?.comment,
				navigate,
				role,
			})
		} else {
			if (id) {
				await RequestsService.editPromo(
					{
						...body,
						startTime:
							state?.req?.startTime === body.startTime
								? state?.req?.startTime
								: `${body.startTime}:00`,
						endTime:
							state?.req?.endTime === body.endTime
								? state?.req?.endTime
								: `${body.endTime}:00`,
						promoTypeId: body.promoTypeId.value,
						promotersMainContactId: body.promotersMainContactId.value,
						employeesIdList: Array.isArray(body.employeesIdList)
							? body.employeesIdList.map((item: any) => {
									return item.value
								})
							: [body.employeesIdList.value],
						id,
					},
					navigate,
					role
				)
			} else {
				if (role === 'User')
					await RequestsService.addPromo(
						{
							...body,
							startTime: `${body.startTime}:00`,
							endTime: `${body.endTime}:00`,
							promoTypeId: body.promoTypeId.value,
							promotersMainContactId: body?.promotersMainContactId?.value,
							employeesIdList: Array.isArray(body?.employeesIdList)
								? body.employeesIdList.map((item: any) => {
										return item.value
									})
								: [body.employeesIdList.value],
						},
						navigate,
						role
					)
				else
					await RequestsService.addPromo(
						{
							...body,
							startTime: `${body.startTime}:00`,
							endTime: `${body.endTime}:00`,
							counterpartyId: body.counterpartyId.value,
							promoTypeId: body.promoTypeId.value,
							promotersMainContactId: body.promotersMainContactId.value,
							employeesIdList: Array.isArray(body.employeesIdList)
								? body.employeesIdList.map((item: any) => {
										return item.value
									})
								: [body.employeesIdList.value],
						},
						navigate,
						role,
						body.counterpartyId.value
					)
			}
		}
	}

	const ComponentModal = () => {
		switch (typeModal) {
			case 'comment':
				return (
					<CommentModal
						id={id}
						comment={{
							renterComment: renterCommentWatch,
							guardComment: guardCommentWatch,
						}}
						close={() => setIsOpen(false)}
					/>
				)
			case 'calendar':
				return <CalendarModal close={() => setIsOpen(false)} />
			default:
				break
		}
	}

	const getMinDate = (): Date => {
		const date = new Date()
		if (!promoTypeIdWatch || !promoTypeIdConnector[promoTypeIdWatch?.value])
			return date
		date.setDate(date.getDate() + promoTypeIdConnector[promoTypeIdWatch?.value])
		return date
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={
					id
						? 'Заявка на проведение промо, фото/видео съемку'
						: 'Новая заявка на проведение промо, фото/видео съемку'
				}
			/>
			<Advertisements position={4} />
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				<Textarea
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Название сторонней организации или фио физического лица с паспортными данными"
					{...register('sideCounterparty')}
					error={errors.sideCounterparty}
					rows={3}
				/>
				<p
					className="text-purple-500 cursor-pointer"
					onClick={() => {
						setIsOpen(true)
						setTypeModal('calendar')
					}}
				>
					Календарь событий
				</p>
				<FieldSelect
					id="promoTypeId"
					placeholder="Вид работ"
					control={control}
					options={types}
					rules={{
						required: 'Поле обязательно к заполнению!',
					}}
					error={errors.promoTypeId}
					required
				/>
				<div className={styles.info}>
					<p className={cn(styles.red, '!text-xl')}>
						<>{console.log(promoTypeIdWatch)}</>
						{promoTypeIdWatch &&
							`Заявка подается за ${promoTypeIdConnector[promoTypeIdWatch?.value]} дней`}
					</p>
				</div>
				<FieldInput
					type="date"
					placeholder="Дата начала действия заявки"
					{...(role !== 'Admin' &&
						role !== 'Manager' &&
						role !== 'Marketer' && {
							min: format(getMinDate(), 'yyyy-MM-dd'),
						})}
					disabled={isDisable(state?.req?.status, role)}
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
					required
				/>
				{startDateWatch && (
					<FieldInput
						type="date"
						placeholder="Дата завершения действия заявки"
						disabled={isDisable(state?.req?.status, role)}
						{...register('endDate', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.endDate}
						required
					/>
				)}
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время начала"
					{...register('startTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startTime}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время завершения"
					{...register('endTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.endTime}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="number"
					min={0}
					placeholder="Количество промоутеров"
					{...register('promotersAmount', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.promotersAmount}
					required
				/>
				<Textarea
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Краткое описание"
					{...register('eventDescription', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.eventDescription}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Наличие звукового сопровождения (количество колонок)"
					min={0}
					{...register('speakersAmount', {
						required: 'Поле обязательно к заполнению!',
					})}
					type="number"
					error={errors.speakersAmount}
					required
				/>
				<div className={styles.info}>
					<p className={styles.red}>
						При использовании звукового оборудования заявку необходимо
						согласовывать в текстовом виде в офисе Администрации с приложением
						доверенности на лицо, подписывающее заявку!
					</p>
				</div>
				{role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
					<>
						<FieldSelect
							id="counterpartyId"
							placeholder="Магазин"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							error={errors.counterpartyId}
							options={counterparty}
							changeProps={async (value) => {
								const data = await UserService.getUserCounterpartyView({
									CounterpartyId: value.value.toString(),
								})
								setUsers(
									data?.employees.map((item) => {
										return {
											label: item.name,
											value: item.id,
										}
									})
								)
							}}
							disabled={state?.req}
							required
						/>
						{counterpartyIdWatch && (
							<>
								<FieldSelect
									id="employeesIdList"
									placeholder="ФИО сотрудников"
									control={control}
									rules={{
										required: 'Поле обязательно к заполнению!',
									}}
									options={users}
									error={errors.employeesIdList}
									disabled={isDisable(state?.req?.status, role)}
									isMulti
									required
								/>
								<FieldSelect
									id="promotersMainContactId"
									placeholder="ФИО ответственного за проведение работ"
									control={control}
									rules={{
										required: 'Поле обязательно к заполнению!',
									}}
									options={users}
									error={errors.promotersMainContactId}
									disabled={isDisable(state?.req?.status, role)}
									required
								/>
							</>
						)}
					</>
				) : role === 'User' &&
				  userInfo?.counterparty !== undefined &&
				  userInfo?.counterparty !== null ? (
					<>
						<FieldSelect
							id="promotersMainContactId"
							placeholder="ФИО ответственного за проведение работ"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.promotersMainContactId}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
						<FieldSelect
							id="employeesIdList"
							placeholder="ФИО сотрудников"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.employeesIdList}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
					</>
				) : role === 'Guard' ? (
					<>
						<FieldSelect
							id="promotersMainContactId"
							placeholder="ФИО ответственного за проведение работ"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.promotersMainContactId}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
						<FieldSelect
							id="employeesIdList"
							placeholder="ФИО сотрудников"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.employeesIdList}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
					</>
				) : null}
				<Textarea
					placeholder="Комментарий"
					{...register('comment')}
					disabled={
						state?.req?.status?.name === 'На рассмотрении'
							? false
							: isDisable(state?.req?.status, role)
					}
					rows={3}
				/>
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.mainContactPhoneNumber ? (
					<div>
						<p>
							Номер телефона ответственного за проведение работ:{' '}
							<Link to={`tel:${state?.req?.mainContactPhoneNumber}`}>
								{state?.req?.mainContactPhoneNumber}
							</Link>
						</p>
					</div>
				) : null}
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.phoneEmergency ? (
					<div>
						<p>
							Номер телефона ответственного за ЧС:{' '}
							<Link to={`tel:${state?.req?.phoneEmergency}`}>
								{state?.req?.phoneEmergency}
							</Link>
						</p>
					</div>
				) : null}
				{role === 'User'
					? renterCommentWatch && (
							<p>Комментарий для арендатора: {renterCommentWatch}</p>
						)
					: role === 'Admin' || role === 'Manager' || role === 'Marketer'
						? (guardCommentWatch || renterCommentWatch) && (
								<div>
									{guardCommentWatch && (
										<p>Комментарий для охраны: {guardCommentWatch}</p>
									)}
									{renterCommentWatch && (
										<p>Комментарий для арендатора: {renterCommentWatch}</p>
									)}
								</div>
							)
						: role === 'Guard' &&
							guardCommentWatch && (
								<p>Комментарий для охраны: {guardCommentWatch}</p>
							)}
				<div className={styles.info} style={{ marginBottom: '10px' }}>
					<p className={styles.red}>*срок рассмотрения заявки 3 рабочих дня</p>
				</div>
				{state?.req ? (
					role === 'User' ? (
						(state?.req?.status?.name === 'На рассмотрении' ?? (
							<Button>Изменить заявку</Button>
						))
					) : role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
						<div className={styles.btns}>
							{!isDisable(state?.req?.status, role) ? (
								<Button>Изменить заявку</Button>
							) : (
								<Button
									appearance="red"
									onClick={async (e) => {
										e.preventDefault()
										await RequestsService.decline(id, () => navigate(-1))
									}}
								>
									Аннулировать
								</Button>
							)}
							<Button
								onClick={(e) => {
									e.preventDefault()
									setIsOpen(true)
									setTypeModal('comment')
								}}
							>
								Добавить комментарий
							</Button>
						</div>
					) : null
				) : (
					<Button>Отправить заявку</Button>
				)}
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<ComponentModal />
			</Modal>
		</>
	)
}
