import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useNavigate } from 'react-router-dom'
import { getType } from 'settings/getUrlType'
import { modalStyle } from 'settings/modalStyle.config'

import { Loader, SubHeading, Table, Title } from '@/components'

import { useAuth } from '@/hooks'

import { RequestsService } from '@/services'

import { IInfo } from '@/types'

import styles from '../../Table.module.scss'
import { StatusModal } from '../modal'

import { requestColumns } from './table.config'

export const ListRequest = (): JSX.Element => {
	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [requestsData, setRequestsData] = useState<IInfo[]>()
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [idModal, setIdModal] = useState<string>('')

	const navigate = useNavigate()
	const { role } = useAuth()

	useEffect(() => {
		fetch()
	}, [])

	const openModal = (id: string) => {
		setIdModal(id)
		setIsOpen(true)
	}

	const closeModal = () => {
		fetch()
		setIsOpen(false)
	}

	const fetch = async () => {
		setIsLoad(true)
		const requests = await RequestsService.getAll({
			IsApproved: false,
			IsNeedConfirmation: true,
		})
		setRequestsData(requests)
		setIsLoad(false)
	}

	const onClick = (item: any) => {
		navigate(getType(item), {
			state: {
				req: item,
			},
		})
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Title title="Новые заявки" />
				{requestsData?.length ? (
					<div className={styles.table}>
						<Table
							dataProps={requestsData.map((item) => {
								return {
									...item,
									startDateStr: new Date(item.startDate).toLocaleDateString(),
									endDateStr: new Date(item.endDate).toLocaleDateString(),
								}
							})}
							columns={requestColumns(onClick, openModal)}
							colVisibility={role !== 'Marketer' ? {} : { icon: false }}
						/>
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<StatusModal id={idModal} close={closeModal} />
			</Modal>
		</>
	)
}
