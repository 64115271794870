import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Loader, SubHeading, Table, Title } from '@/components'

import { RequestsService } from '@/services'

import { getType } from '@/settings'

import { IInfo } from '@/types'

import styles from '../Table.module.scss'

import { requestColumns } from './table.config'

export const ActiveList = (): JSX.Element => {
	const { role } = useAuth()

	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [requestsData, setRequestsData] = useState<IInfo[]>()

	const navigate = useNavigate()

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const requests = await RequestsService.getAll({
			IsApproved: true,
			IsNeedConfirmation: false,
		})
		setRequestsData(requests)
		setIsLoad(false)
	}

	const onClick = (item: any) => {
		navigate(getType(item), {
			state: {
				req: item,
			},
		})
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<div className={styles.wrapper}>
			<Title title="Действующие заявки" />
			{role === 'Admin' || role === 'Manager' ? (
				<Button
					style={{ width: '200px', marginLeft: 'auto' }}
					onClick={async () => await RequestsService.generateReport()}
				>
					Выгрузить в excel
				</Button>
			) : null}
			{requestsData?.length ? (
				<div className={styles.table}>
					<Table
						dataProps={requestsData
							.filter((item) => {
								if (role === 'Guard') {
									return item.type !== 'Заявка на пропуск'
								} else return item
							})
							.map((item) => {
								return {
									...item,
									startDateStr: new Date(item.startDate).toLocaleDateString(),
									endDateStr: new Date(item.endDate).toLocaleDateString(),
								}
							})}
						columns={requestColumns(role, onClick, fetch)}
					/>
				</div>
			) : (
				<SubHeading title="Данных нет" />
			)}
		</div>
	)
}
