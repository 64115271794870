/* eslint-disable @typescript-eslint/no-unused-expressions */
import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import { IPermit } from '../types/Permits.type'

const getPermitsUrl = (url: string) => `/permits/${url}`

export const PermitsService = {
	async getAll({
		CounterpartyName,
		RequestIdentifier,
		EmployeeName,
		IsGetAll,
	}: {
		CounterpartyName?: string
		RequestIdentifier?: string
		EmployeeName?: string
		IsGetAll?: boolean
	}): Promise<IPermit[]> {
		try {
			let params = new URLSearchParams()
			CounterpartyName !== undefined
				? params.append('CounterpartyName', CounterpartyName)
				: null
			RequestIdentifier !== undefined
				? params.append('RequestIdentifier', RequestIdentifier)
				: null
			EmployeeName !== undefined
				? params.append('EmployeeName', EmployeeName)
				: null
			IsGetAll !== undefined
				? params.append('IsGetAll', String(IsGetAll))
				: null
			const { data } = await axiosPrivate.get<IPermit[]>(
				getPermitsUrl(`all?${params}`)
			)
			return data
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async getInfo(id: string, error?: boolean) {
		try {
			const { data } = await axiosPrivate.get<IPermit>(
				getPermitsUrl(`get_info?PermitId=${id}`)
			)
			return data
		} catch (e: any) {
			error && toast.error(e.message)
			return e.message
		}
	},

	async check(id: string): Promise<number> {
		try {
			const { status } = await axiosPrivate.get<any>(
				getPermitsUrl(`all?PermitId=${id}`)
			)
			return status
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async cancel(id: string, update: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getPermitsUrl(`cancel?PermitId=${id}`))
			toast.success('Пропуск аннулирован')
			update()
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async setActive(id: string, update: () => void): Promise<void> {
		try {
			await axiosPrivate.patch<any>(getPermitsUrl(`set_active?PermitId=${id}`))
			toast.success('Пропуск восстановлен')
			update()
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async addTemp(id: string): Promise<number> {
		try {
			const { status } = await axiosPrivate.post<any>(getPermitsUrl('all'), {
				id,
			})
			return status
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async add(
		body: { employeeId: string; requestId: string },
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.post(getPermitsUrl('add'), body)
			toast.success('Пропуск выдан')
			update()
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},

	async delete(id: string): Promise<number> {
		try {
			const { status } = await axiosPrivate.delete<any>(
				getPermitsUrl(`all?PermitId=${id}`)
			)
			return status
		} catch (e: any) {
			toast.error(e.message)
			return e.message
		}
	},
}
