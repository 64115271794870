import cn from 'clsx'
import { addDays, format } from 'date-fns'
import { nanoid } from 'nanoid'
import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdDelete } from 'react-icons/md'
import Modal from 'react-modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Advertisements,
	Button,
	FieldInput,
	FieldSelect,
	Input,
	Loader,
	Textarea,
	Title,
} from '@/components'

import { useAuth } from '@/hooks'

import { EmployeesService, RequestsService, UserService } from '@/services'

import { isDisable, modalStyle } from '@/settings'

import {
	IAddLoadingWithRamp,
	ICarIdentifier,
	ILoading,
	ISelect,
	IUnloading,
} from '@/types'

import styles from './Form.module.scss'
import { CarModal, ImportExportModal } from './modal'
import { CommentModal } from './modal/CommentModal'

export interface IImportExport {
	name: string
	amount: number | string
	number: string
}
type TAddLoadingWithRampForm = IAddLoadingWithRamp & {
	counterpartyId?: string
}

export const RequestNewImportExport = (): JSX.Element => {
	const { role } = useAuth()
	const navigate = useNavigate()
	const { id } = useParams()
	const { state } = useLocation()

	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [type, setType] = useState<
		'loading' | 'unloading' | 'carIdentifier' | 'comment'
	>('loading')

	const [radioNoValue, setRadioNoValue] = useState(false)
	const [loadingArr, setLoadingArr] = useState<ILoading[]>([])
	const [unloadingArr, setUnloadingArr] = useState<IUnloading[]>([])
	const [carIdentifierArr, setCarIdentifierArr] = useState<ICarIdentifier[]>([])
	const [isUseHudraulicLift, setIsUseHudraulicLift] = useState(false)

	const [users, setUsers] = useState<ISelect[]>([])
	const [counterparty, setCounterparty] = useState<ISelect[]>()

	const fetch = async (id?: string) => {
		setIsLoad(true)
		if (role === 'User') {
			const users = await EmployeesService.getUser()
			setUsers(
				users?.map((item) => {
					return {
						label: item.name,
						value: item.id,
					}
				})
			)
		} else if (role === 'Admin' || role === 'Manager') {
			const data = await UserService.getUserAll()
			setCounterparty(
				data
					.filter((item) => item.counterparty !== null)
					.map((item) => {
						return {
							label: item.counterparty.name,
							value: item.counterparty.id,
						}
					})
			)
			if (state?.req) {
				const users = await UserService.getUserCounterpartyView({
					CounterpartyId: state?.req?.counterparty?.id,
				})

				setUsers(
					users?.employees.map((item) => {
						return {
							label: item.name,
							value: item.id,
						}
					})
				)
			}
		}
		setIsLoad(false)
	}

	useEffect(() => {
		clearErrors('carIdentifier')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carIdentifierArr])

	const openModal = (
		type: 'loading' | 'unloading' | 'carIdentifier' | 'comment'
	) => {
		setType(type)
		setIsOpen(true)
	}

	const Component = () => {
		switch (type) {
			case 'loading':
				return (
					<ImportExportModal
						title="Внос"
						arr={loadingArr}
						setArr={setLoadingArr}
						close={() => setIsOpen(false)}
					/>
				)
			case 'unloading':
				return (
					<ImportExportModal
						title="Вынос"
						arr={unloadingArr}
						setArr={setUnloadingArr}
						close={() => setIsOpen(false)}
					/>
				)
			case 'carIdentifier':
				return (
					<CarModal
						arr={carIdentifierArr}
						setArr={setCarIdentifierArr}
						close={() => setIsOpen(false)}
					/>
				)
			case 'comment':
				return (
					<CommentModal
						id={id}
						close={() => setIsOpen(false)}
						comment={{
							renterComment: renterCommentWatch,
							guardComment: guardCommentWatch,
						}}
					/>
				)
			default:
				return <></>
		}
	}

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setError,
		watch,
		clearErrors,
	} = useForm<TAddLoadingWithRampForm>({
		mode: 'onChange',
		defaultValues: async () => {
			if (id) {
				const data = await RequestsService.getInfo(id, false)
				setRadioNoValue(
					data?.type === 'Внос/вынос ТМЦ, через рампу' ? true : false
				)
				setIsUseHudraulicLift(data?.useHydraulicLift)
				setLoadingArr(data ? data?.loading : [])
				setUnloadingArr(data ? data?.unloading : [])
				setCarIdentifierArr(data ? data?.carIdentifier : [])

				!isDisable(state?.req?.status, role)
					? fetch(data?.requestCreator?.id)
					: setIsLoad(false)

				return {
					...data,
					useHydraulicLift: true,
					mainContact: {
						label: data?.mainContact?.name,
						value: data?.mainContact?.id,
					},
					counterpartyId: {
						label: data?.counterparty?.name,
						value: data?.counterparty?.id,
					},
					comment: data?.comment,
				}
			} else {
				fetch()
			}
		},
	})

	const startDateWatch = watch('startDate')
	const counterpartyIdWatch = watch('counterpartyId')
	const guardCommentWatch = watch('guardComment')
	const renterCommentWatch = watch('renterComment')

	const handleRadio = (e: any) => {
		setRadioNoValue(!radioNoValue)
	}

	const onSubmit: SubmitHandler<any> = async (body) => {
		clearErrors('carIdentifier')
		if (state?.req?.status?.name === 'На рассмотрении') {
			RequestsService.editComment({
				RequestId: id,
				comment: body?.comment,
				navigate,
				role,
			})
		} else {
			if (!radioNoValue) {
				if (id) {
					await RequestsService.editLoadingWithoutRamp(
						{
							...body,
							sideCounterparty: body.sideCounterparty
								? body.sideCounterparty
								: '',
							startTime:
								state?.req?.startTime === body.startTime
									? state?.req?.startTime
									: `${body.startTime}:00`,
							endTime:
								state?.req?.endTime === body.endTime
									? state?.req?.endTime
									: `${body.endTime}:00`,
							loading: loadingArr,
							unloading: unloadingArr,
							mainContact: body.mainContact.value,
							id,
						},
						navigate,
						role
					)
				} else {
					if (role === 'User')
						await RequestsService.addLoadingWithoutRamp(
							{
								...body,
								startTime: `${body.startTime}:00`,
								endTime: `${body.endTime}:00`,
								loading: loadingArr,
								unloading: unloadingArr,
								mainContact: body.mainContact.value,
							},
							navigate,
							role
						)
					else
						await RequestsService.addLoadingWithoutRamp(
							{
								...body,
								startTime: `${body.startTime}:00`,
								endTime: `${body.endTime}:00`,
								loading: loadingArr,
								unloading: unloadingArr,
								mainContact: body.mainContact.value,
							},
							navigate,
							role,
							body.counterpartyId.value
						)
				}
			} else {
				if (carIdentifierArr.length === 0) {
					setError('carIdentifier', {
						message: 'Поле обязательно к заполнению!',
					})
				} else {
					clearErrors('carIdentifier')
					if (id) {
						await RequestsService.editLoadingWithRamp(
							{
								...body,
								startTime:
									state?.req?.startTime === body.startTime
										? state?.req?.startTime
										: `${body.startTime}:00`,
								endTime:
									state?.req?.endTime === body.endTime
										? state?.req?.endTime
										: `${body.endTime}:00`,
								loading: loadingArr,
								unloading: unloadingArr,
								mainContact: body.mainContact.value,
								carIdentifier: carIdentifierArr,
								id,
							},
							navigate,
							role
						)
					} else {
						if (role === 'User')
							await RequestsService.addLoadingWithRamp(
								{
									...body,
									startTime: `${body.startTime}:00`,
									endTime: `${body.endTime}:00`,
									loading: loadingArr,
									unloading: unloadingArr,
									carIdentifier: carIdentifierArr,
									mainContact: body.mainContact.value,
								},
								navigate,
								role
							)
						else {
							await RequestsService.addLoadingWithRamp(
								{
									...body,
									startTime: `${body.startTime}:00`,
									endTime: `${body.endTime}:00`,
									loading: loadingArr,
									unloading: unloadingArr,
									carIdentifier: carIdentifierArr,
									mainContact: body.mainContact.value,
								},
								navigate,
								role,
								body.counterpartyId.value
							)
						}
					}
				}
			}
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={id ? 'Заявка на внос/вынос' : 'Новая заявка на внос/вынос'}
			/>
			<Advertisements position={2} />
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				<Textarea
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Название сторонней организации или фио физического лица с паспортными данными"
					{...register('sideCounterparty')}
					error={errors.sideCounterparty}
					rows={3}
				/>
				<FieldInput
					type="date"
					placeholder="Дата начала действия заявки"
					min={format(
						startDateWatch ? new Date(startDateWatch) : new Date(),
						'yyyy-MM-dd'
					)}
					max={format(
						startDateWatch ? new Date(startDateWatch) : addDays(new Date(), 14),
						'yyyy-MM-dd'
					)}
					disabled={isDisable(state?.req?.status, role)}
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
					required
				/>
				{startDateWatch && (
					<FieldInput
						type="date"
						placeholder="Дата завершения действия заявки"
						min={format(new Date(startDateWatch), 'yyyy-MM-dd')}
						max={format(addDays(new Date(startDateWatch), 14), 'yyyy-MM-dd')}
						disabled={isDisable(state?.req?.status, role)}
						{...register('endDate', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.endDate}
						required
					/>
				)}
				<FieldInput
					type="time"
					placeholder="Время начала"
					{...register('startTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startTime}
					disabled={isDisable(state?.req?.status, role)}
					required
				/>
				<FieldInput
					type="time"
					placeholder="Время завершения"
					{...register('endTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.endTime}
					disabled={isDisable(state?.req?.status, role)}
					required
				/>
				{role === 'Admin' || role === 'Manager' ? (
					<>
						<FieldSelect
							id="counterpartyId"
							placeholder="Магазин"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							error={errors.counterpartyId}
							options={counterparty}
							changeProps={async (value) => {
								const data = await UserService.getUserCounterpartyView({
									CounterpartyId: value.value.toString(),
								})
								setUsers(
									data?.employees.map((item) => {
										return {
											label: item.name,
											value: item.id,
										}
									})
								)
							}}
							disabled={state?.req}
							required
						/>
						{counterpartyIdWatch && (
							<FieldSelect
								id="mainContact"
								placeholder="ФИО ответственного за проведение работ"
								control={control}
								rules={{
									required: 'Поле обязательно к заполнению!',
								}}
								options={users}
								error={errors.mainContact}
								disabled={isDisable(state?.req?.status, role)}
								required
							/>
						)}
					</>
				) : (
					<FieldSelect
						id="mainContact"
						placeholder="ФИО ответственного за проведение работ"
						control={control}
						rules={{
							required: 'Поле обязательно к заполнению!',
						}}
						options={users}
						error={errors.mainContact}
						disabled={isDisable(state?.req?.status, role)}
						required
					/>
				)}
				<ImportExport
					label="Товары на внос"
					disabled={isDisable(state?.req?.status, role)}
					type="loading"
					arr={loadingArr}
					setArr={setLoadingArr}
					openModal={() => openModal('loading')}
				/>
				<ImportExport
					label="Товары на вынос"
					disabled={isDisable(state?.req?.status, role)}
					type="unloading"
					arr={unloadingArr}
					setArr={setUnloadingArr}
					openModal={() => openModal('unloading')}
				/>
				<div className={styles.radioBlock}>
					<p>Необходимость разгрузки через дебаркадеры</p>
					<label htmlFor="radioYes">
						<Input
							type="radio"
							name="radiob"
							id="radioYes"
							disabled={state?.req}
							checked={radioNoValue}
							onChange={handleRadio}
						/>
						Да
					</label>
					<label htmlFor="radioNo">
						<Input
							type="radio"
							name="radiob"
							id="radioNo"
							disabled={state?.req}
							checked={!radioNoValue}
							onChange={handleRadio}
						/>
						Нет
					</label>
				</div>
				{!radioNoValue ? (
					<></>
				) : (
					<>
						<FieldInput
							type="number"
							placeholder="Номер дебаркадера"
							{...register('rampIdentifier', {
								required: 'Поле обязательно к заполнению!',
							})}
							min={1}
							disabled={isDisable(state?.req?.status, role)}
							error={errors.rampIdentifier}
							required
						/>
						<ImportExport
							label="Авто"
							arr={carIdentifierArr}
							isRequired={true}
							disabled={isDisable(state?.req?.status, role)}
							setArr={setCarIdentifierArr}
							type="carIdentifier"
							openModal={() => openModal('carIdentifier')}
							error={errors.carIdentifier}
							clearErrors={clearErrors}
						/>
						<label htmlFor="useHudraulicLift" style={{ marginBottom: '5px' }}>
							<Input
								type="checkbox"
								name="useHudraulicLift"
								id="useHudraulicLift"
								disabled={isDisable(state?.req?.status, role)}
								{...register('useHudraulicLift')}
								error={errors.useHudraulicLift}
								style={{ marginRight: '5px' }}
								defaultChecked={isUseHudraulicLift}
							/>
							Гидравлический подъемник
						</label>
					</>
				)}
				<Textarea
					placeholder="Комментарий"
					{...register('comment')}
					disabled={
						state?.req?.status?.name === 'На рассмотрении'
							? false
							: isDisable(state?.req?.status, role)
					}
					rows={3}
				/>
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.mainContactPhoneNumber ? (
					<div style={{ margin: '10px 0' }}>
						<p>
							Номер телефона ответственного за проведение работ:{' '}
							<Link to={`tel:${state?.req?.mainContactPhoneNumber}`}>
								{state?.req?.mainContactPhoneNumber}
							</Link>
						</p>
					</div>
				) : null}
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.phoneEmergency ? (
					<div>
						<p>
							Номер телефона ответственного за ЧС:{' '}
							<Link to={`tel:${state?.req?.phoneEmergency}`}>
								{state?.req?.phoneEmergency}
							</Link>
						</p>
					</div>
				) : null}
				{role === 'User'
					? renterCommentWatch && (
							<p>Комментарий для арендатора: {renterCommentWatch}</p>
						)
					: role === 'Admin' || role === 'Manager' || role === 'Marketer'
						? (guardCommentWatch || renterCommentWatch) && (
								<div>
									{guardCommentWatch && (
										<p>Комментарий для охраны: {guardCommentWatch}</p>
									)}
									{renterCommentWatch && (
										<p>Комментарий для арендатора: {renterCommentWatch}</p>
									)}
								</div>
							)
						: role === 'Guard' &&
							guardCommentWatch && (
								<p>Комментарий для охраны: {guardCommentWatch}</p>
							)}
				{state?.req ? (
					role === 'User' ? (
						<>
							{state?.req?.status?.name === 'На рассмотрении' && (
								<Button>Изменить заявку</Button>
							)}
							{state?.req?.status?.name !== 'Аннулировано' && (
								<div>
									<Button className="m-auto mt-2">Изменить комментарий</Button>
								</div>
							)}
							{console.log(state?.req?.status)}
						</>
					) : role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
						<div className={styles.btns}>
							{!isDisable(state?.req?.status, role) ? (
								<Button>Изменить заявку</Button>
							) : (
								<Button
									appearance="red"
									onClick={async (e) => {
										e.preventDefault()
										await RequestsService.decline(id, () => navigate(-1))
									}}
								>
									Аннулировать
								</Button>
							)}
							<Button
								onClick={(e) => {
									e.preventDefault()
									openModal('comment')
								}}
							>
								Добавить комментарий
							</Button>
						</div>
					) : null
				) : (
					<Button>Отправить заявку</Button>
				)}
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<Component />
			</Modal>
		</>
	)
}

const ImportExport: FC<{
	label: string
	arr: any[]
	type: 'loading' | 'unloading' | 'carIdentifier'
	isRequired?: boolean
	error?: any
	disabled: boolean
	setArr: (arr: any[]) => void
	openModal: (type: 'loading' | 'unloading' | 'carIdentifier') => void
	clearErrors?: () => void
}> = ({ label, arr, type, error, isRequired, disabled, setArr, openModal }) => {
	const onClick = (id: number) => {
		setArr(arr.filter((item, idx) => idx !== id))
	}
	return (
		<div className={styles.importExport}>
			<p
				className={cn(styles.title, {
					[styles.required]: isRequired,
				})}
			>
				{label}
			</p>
			<table>
				<thead>
					{type !== 'carIdentifier' ? (
						<tr>
							<th>Название</th>
							<th>Кол-во</th>
							<th>Удалить</th>
						</tr>
					) : (
						<tr>
							<th>Марка</th>
							<th>Номер</th>
							<th>Удалить</th>
						</tr>
					)}
				</thead>
				<tbody>
					{arr?.length ? (
						arr.map((item, idx) => (
							<tr key={nanoid()}>
								<td>{item.name}</td>
								<td>{type === 'carIdentifier' ? item.number : item.amount}</td>
								<td>
									<MdDelete onClick={() => onClick(idx)} size={25} />
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={3}>{label} нет</td>
						</tr>
					)}
				</tbody>
			</table>
			{!disabled ? (
				<Button
					onClick={(e) => {
						e.preventDefault()
						openModal(type)
					}}
				>
					{`Добавить ${label.toLocaleLowerCase()}`}
				</Button>
			) : null}
			{error && <p className={styles.error}>{error.message}</p>}
		</div>
	)
}
