import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router'
import { EmployeesService } from 'services/Employees.service'
import { IEmployeeUser } from 'types/Employees.type'

import { Button, SubHeading } from '@/components'

import { isPassStatus } from '@/pages'

import { ModalType } from '../Employees'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	employee: IEmployeeUser
	role: string
	open: (type: ModalType) => void
	close: () => void
	update: () => void
}> = ({ employee, role, open, close, update }) => {
	const navigate = useNavigate()

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Управление" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<Button onClick={() => open('edit')}>Редактирование</Button>
				{/* {role === 'User' && !isPassStatus(employee) && (
					<Button
						className="cursor-pointer"
						onClick={() =>
							navigate(`/request/new/pass`, {
								state: {
									user: employee,
								},
							})
						}
					>
						Заявка на пропуск
					</Button>
				)} */}
				<Button
					appearance="red"
					onClick={async () =>
						await EmployeesService.delete(employee.id, update, close)
					}
				>
					Удалить
				</Button>
			</div>
		</div>
	)
}
