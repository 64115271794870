import { useEffect, useState } from 'react'
import Modal from 'react-modal'

import { Loader, SubHeading, Table, Title } from '@/components'

import { UserService } from '@/services'

import { modalStyle } from '@/settings'

import { IUser } from '@/types'

import styles from './List.module.scss'
import { shopsColumns } from './Table.config'
import {
	AddMessageModal,
	ChangeInfoModal,
	ChangePasswordModal,
	ChoiceModal,
	HistoryMessageModal,
} from './modal'

export type TypeModal = 'choice' | 'password' | 'message' | 'history' | 'info'

export const ShopList = (): JSX.Element => {
	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [shopsData, setShopsData] = useState<IUser[]>([])
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)
	const [shopProps, setShopProps] = useState<IUser>()
	const [typeModal, setTypeModal] = useState<TypeModal>('choice')

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const shops = await UserService.getUserAll()
		setShopsData(shops.filter((shop) => shop.counterparty !== null))
		setIsLoad(false)
		closeModal()
	}

	const openModal = ({ shop, type }: { shop?: IUser; type: TypeModal }) => {
		setTypeModal(type)
		shop && setShopProps(shop)
		setIsOpen(true)
	}

	const closeModal = () => {
		setIsOpen(false)
	}

	const Component = () => {
		switch (typeModal) {
			case 'choice':
				return (
					<ChoiceModal
						open={openModal}
						close={closeModal}
						shop={shopProps}
						update={fetch}
					/>
				)
			case 'password':
				return (
					<ChangePasswordModal
						email={shopProps.email}
						close={closeModal}
						update={fetch}
					/>
				)
			case 'history':
				return (
					<HistoryMessageModal
						id={shopProps.id}
						close={closeModal}
						update={fetch}
					/>
				)
			case 'message':
				return <AddMessageModal id={shopProps.id} close={closeModal} />
			case 'info':
				return (
					<ChangeInfoModal
						id={shopProps.id}
						close={closeModal}
						update={fetch}
					/>
				)
			default:
				break
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<div className={styles.wrapper}>
				<Title title="Список магазинов" />
				{shopsData?.length ? (
					<div className={styles.table}>
						<Table dataProps={shopsData} columns={shopsColumns(openModal)} />
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<Component />
			</Modal>
		</>
	)
}
