import Cookies from 'js-cookie'
import { FC, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'

import { Button, Loader } from '@/components'

import { UserService } from '@/services'

import { AuthContext, AuthContextInterface } from '@/provider'

import { modalStyle } from '@/settings'

import { IUser } from '@/types'

import {
	AddUserModal,
	ChangeUserMailModal,
	ChangeUserPasswordModal,
	ChangeUserPhoneModal,
	ChoiceModal,
} from './Modal'
import styles from './UserInfo.module.scss'

interface IUserInfo {
	role?: string
}

export type TypeModal = 'email' | 'password' | 'phone' | 'choice' | 'adduser'

export const UserInfo: FC<IUserInfo> = ({ role = 'User' }): JSX.Element => {
	const { setRole, setToken, serRefreshToken } =
		useContext<AuthContextInterface>(AuthContext)
	const [type, setType] = useState<TypeModal>()
	const [userInfo, setUserInfo] = useState<IUser>()
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
	const [isLoad, setIsLoad] = useState<boolean>(false)

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const data = await UserService.getInfo()
		setUserInfo(data)
		setIsLoad(false)
	}

	const exitFunc = () => {
		setRole && setRole('')
		setToken && setToken('')
		serRefreshToken && serRefreshToken('')
		Cookies.remove('token')
		Cookies.remove('refreshToken')
		Cookies.remove('expiration')
		Cookies.remove('role')
	}

	function openModal(type: TypeModal) {
		setType(type)
		setModalIsOpen(true)
	}
	function closeModal() {
		setModalIsOpen(false)
	}

	const ModalComponent = () => {
		switch (type) {
			case 'choice':
				return <ChoiceModal open={openModal} close={closeModal} />
			case 'email':
				return <ChangeUserMailModal close={closeModal} update={fetch} />
			case 'password':
				return <ChangeUserPasswordModal close={closeModal} update={fetch} />
			case 'phone':
				return <ChangeUserPhoneModal close={closeModal} update={fetch} />
			case 'adduser':
				return <AddUserModal close={closeModal} />
			default:
				return <></>
		}
	}

	const showRole = (role: string) => {
		switch (role) {
			case 'User':
				return 'Юридическое лицо'
			case 'Manager':
				return 'Менеджер'
			case 'Guard':
				return 'Охранник'
			case 'Dispatcher':
				return 'Диспетчер'
			case 'Marketer':
				return 'Маркетолог'
			case 'Admin':
				return 'Администратор'
			default:
				return '-'
		}
	}

	if (isLoad) {
		return <Loader />
	}

	if (role === 'Ur') {
		return (
			<div className={styles.mainInfo}>
				<p>
					ФИО:
					<span>{userInfo?.userName ? <>{userInfo?.userName}</> : '-'}</span>
				</p>
				<p>
					Email:
					<span>
						{userInfo?.email ? (
							<Link to={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<p>
					Телефон:
					<span>
						{userInfo?.phoneNumber ? (
							<Link to={`tel:${userInfo?.phoneNumber}`}>
								{userInfo?.phoneNumber}
							</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<div className={styles.btns}>
					<Button onClick={() => openModal('choice')}>Управление</Button>
					<Button onClick={exitFunc} appearance="red">
						Выйти
					</Button>
				</div>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					ariaHideApp={false}
					style={modalStyle}
				>
					<ModalComponent />
				</Modal>
			</div>
		)
	}

	if (role === 'User') {
		return (
			<div className={styles.mainInfo}>
				<p>
					Название магазина:
					<span>{userInfo?.counterparty?.name ?? '-'}</span>
				</p>

				<p>
					Юр. лицо:
					<span>{userInfo?.counterparty?.legalName ?? '-'}</span>
				</p>

				<p>
					ИНН:
					<span>{userInfo?.counterparty?.itn ?? '-'}</span>
				</p>

				<p>
					Email:
					<span>
						{userInfo?.email ? (
							<Link to={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link>
						) : (
							'-'
						)}
					</span>
				</p>

				<p>
					Телефон:
					<span>
						{userInfo?.phoneNumber ? (
							<Link to={`tel:${userInfo?.phoneNumber}`}>
								{userInfo?.phoneNumber}
							</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<div className={styles.btns}>
					<Button onClick={() => openModal('choice')}>Управление</Button>
					<Button onClick={exitFunc} appearance="red">
						Выйти
					</Button>
				</div>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					ariaHideApp={false}
					style={modalStyle}
				>
					<ModalComponent />
				</Modal>
			</div>
		)
	} else if (role === 'Admin') {
		return (
			<div className={styles.mainInfo}>
				<p>
					ФИО:
					<span>{userInfo?.userName ?? '-'}</span>
				</p>
				<p>
					Должность:
					<span>{showRole(role) ?? '-'}</span>
				</p>
				<p>
					Email:
					<span>
						{userInfo?.email ? (
							<Link to={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<p>
					Телефон:
					<span>
						{userInfo?.phoneNumber ? (
							<Link to={`tel:${userInfo?.phoneNumber}`}>
								{userInfo?.phoneNumber}
							</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<div className={styles.btns}>
					<Button onClick={() => openModal('adduser')}>
						Добавить пользователя
					</Button>
					<Button onClick={exitFunc} appearance="red">
						Выйти
					</Button>
				</div>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					ariaHideApp={false}
					style={modalStyle}
				>
					<ModalComponent />
				</Modal>
			</div>
		)
	} else if (role === 'Manager') {
		return (
			<div className={styles.mainInfo}>
				<p>
					ФИО:
					<span>{userInfo?.userName ?? '-'}</span>
				</p>
				<p>
					Должность:
					<span>{showRole(role) ?? '-'}</span>
				</p>
				<p>
					Email:
					<span>
						{userInfo?.email ? (
							<Link to={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<p>
					Телефон:
					<span>
						{userInfo?.phoneNumber ? (
							<Link to={`tel:${userInfo?.phoneNumber}`}>
								{userInfo?.phoneNumber}
							</Link>
						) : (
							'-'
						)}
					</span>
				</p>
				<div className={styles.btns}>
					<Button onClick={() => openModal('adduser')}>
						Добавить пользователя
					</Button>
					<Button onClick={() => openModal('choice')}>Управление</Button>
					<Button onClick={exitFunc} appearance="red">
						Выйти
					</Button>
				</div>
				<Modal
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					ariaHideApp={false}
					style={modalStyle}
				>
					<ModalComponent />
				</Modal>
			</div>
		)
	}
	return (
		<div className={styles.mainInfo}>
			<p>
				ФИО:
				<span>{userInfo?.userName ?? '-'}</span>
			</p>
			<p>
				Должность:
				<span>{showRole(role) ?? '-'}</span>
			</p>
			<p>
				Email:
				<span>
					{userInfo?.email ? (
						<Link to={`mailto:${userInfo?.email}`}>{userInfo?.email}</Link>
					) : (
						'-'
					)}
				</span>
			</p>
			<p>
				Телефон:
				<span>
					{userInfo?.phoneNumber ? (
						<Link to={`tel:${userInfo?.phoneNumber}`}>
							{userInfo?.phoneNumber}
						</Link>
					) : (
						'-'
					)}
				</span>
			</p>
			<Button onClick={() => openModal('choice')}>Управление</Button>
			<Button onClick={exitFunc} appearance="red">
				Выйти
			</Button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<ModalComponent />
			</Modal>
		</div>
	)
}
