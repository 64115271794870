import { Button } from '..'
import { FC, useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RequestsService } from 'services/Requests.service'

import { FieldInput, Heading } from '@/components'

export const NumberCheck: FC<{ className: string }> = ({ className }) => {
	const [reqInfo, setReqInfo] = useState<string>()

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<{ requestId: string }>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<{ requestId: string }> = useCallback(
		async (body) => {
			const { data, status } = await RequestsService.status(body.requestId)
			if (status === 404) {
				setError('requestId', { message: 'Заявка с таким номером не найдена' })
			} else if (status === 400)
				setError('requestId', { message: 'Неверный формат номера заявки' })
			else if (status === 200) setReqInfo(data)
		},
		[setError, setReqInfo]
	)

	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)} className={className}>
				<Heading title="Статус заявки" />
				<FieldInput
					{...register('requestId', {
						required: 'Номер заявки обязательный!',
					})}
					error={errors.requestId}
					placeholder="Номер заявки"
				/>
				{reqInfo && (
					<p className="self-center mb-1 font-semibold text-stone-600 text-xl">
						{reqInfo}
					</p>
				)}
				<Button appearance="white" type="submit">
					Проверить
				</Button>
			</form>
		</div>
	)
}
