import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from '@/components'

import { IInfo } from '@/types'

import styles from '../Table.module.scss'

const columnHelperRequests = createColumnHelper<
	IInfo & { btns?: any; startDateStr: string; endDateStr: string }
>()

export const archiveRequestColumns = (goTo: (url: any) => void) => [
	columnHelperRequests.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер заявки</span>,
	}),
	columnHelperRequests.accessor('type', {
		size: 'auto' as unknown as number,
		header: () => <span>Тип заявки</span>,
		meta: {
			filterVariant: 'select',
		},
	}),
	columnHelperRequests.accessor('startDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата начала</span>,
	}),
	columnHelperRequests.accessor('endDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата завершения</span>,
	}),
	columnHelperRequests.accessor('counterparty.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Магазин</span>,
	}),
	columnHelperRequests.accessor('mainContactName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО сотрудника</span>,
	}),
	columnHelperRequests.accessor('mainContactPhoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона сотрудник</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.mainContactPhoneNumber}`}>
				{row.original.mainContactPhoneNumber}
			</Link>
		),
	}),
	columnHelperRequests.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => goTo(row.original)}>Подробнее</Button>
			</div>
		),
	}),
]
