import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { IEmployeeUser } from 'types/Employees.type'

import { Button, FieldInput, SubHeading } from '@/components'

import { EmployeesService } from '@/services'

import { IForm } from '../Employees'

import styles from './Modal.module.scss'

export const EmployeesModal: FC<{
	title: string
	fields: IEmployeeUser
	type: 'edit' | 'add'
	update: () => void
	close: () => void
}> = ({ title, fields, type, close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IForm>({
		mode: 'onChange',
		defaultValues: {
			position: fields?.position,
			phoneNumber: fields?.phoneNumber,
			surname: fields?.name.split(' ')[0],
			name: fields?.name.split(' ')[1],
			lastName: fields?.name.split(' ')[2],
		},
	})

	const onSubmit: SubmitHandler<IForm> = async (body) => {
		if (type === 'edit') {
			await EmployeesService.edit(
				fields.id,
				{
					name: `${body.surname} ${body.name} ${body.lastName}`,
					phoneNumber: body.phoneNumber,
					position: body.position,
				},
				update
			)
		} else {
			await EmployeesService.add(
				{
					name: `${body.surname} ${body.name} ${body.lastName}`,
					phoneNumber: body.phoneNumber,
					position: body.position,
				},
				update
			)
		}
		update()
		close()
	}

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title={title} />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Фамилия"
					{...register('surname', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.surname}
					disabled={!!fields?.name}
					required
				/>
				<FieldInput
					placeholder="Имя"
					{...register('name', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.name}
					disabled={!!fields?.name}
					required
				/>
				<FieldInput
					placeholder="Отчество"
					{...register('lastName', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.lastName}
					disabled={!!fields?.name}
					required
				/>
				<FieldInput
					placeholder="Должность"
					{...register('position', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.position}
					disabled={!!fields?.position}
					required
				/>
				<FieldInput
					placeholder="Номер телефона"
					{...register('phoneNumber', {
						required: 'Поле обязательно к заполнению!',
						pattern: {
							value: /(\+7|7|8)+([0-9]){10}/gm, //eslint-disable-line
							message: 'Формат номера телефона +79123456789',
						},
					})}
					required
					error={errors.phoneNumber}
				/>
				{type === 'add' ? (
					<Button appearance="white">Добавить</Button>
				) : (
					<Button appearance="white">Редактировать</Button>
				)}
			</form>
		</div>
	)
}
