import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { IInfo } from 'types/Request.type'

import { Button } from '@/components'

import styles from './List.module.scss'

const columnHelper = createColumnHelper<IInfo & { btns?: any }>()

export const userColumns = (goTo: (url: any) => void) => [
	columnHelper.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер</span>,
	}),
	columnHelper.accessor('applicationDate', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата</span>,
		cell: ({ row }) =>
			`${new Date(row.original.applicationDate).toLocaleDateString()}`,
	}),
	columnHelper.accessor('type', {
		size: 'auto' as unknown as number,
		header: () => <span>Тип заявки</span>,
	}),
	columnHelper.accessor('status.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус</span>,
	}),
	columnHelper.accessor('mainContactName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО сотрудника</span>,
	}),
	columnHelper.accessor('mainContactPhoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона сотрудник</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.mainContactPhoneNumber}`}>
				{row.original.mainContactPhoneNumber}
			</Link>
		),
	}),
	columnHelper.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Магазин</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => goTo(row.original)}>Подробнее</Button>
			</div>
		),
	}),
]
