import Cookies from 'js-cookie'
import { nanoid } from 'nanoid'
import { NotFound } from 'pages/NotFound/NotFound'
import { Route, Routes } from 'react-router-dom'

import { withLayout } from './Layout/Layout'
import { useAuth } from './hooks/useAuth'
import { RoleUser, routes } from './routes.data'

function App() {
	const { role, setRole, setToken, serRefreshToken } = useAuth()

	const tokenCookies = Cookies.get('token')
	const refreshToken = Cookies.get('refreshToken')
	const roleCookies = Cookies.get('role')

	if (tokenCookies !== undefined) setToken?.(tokenCookies)
	if (refreshToken !== undefined) serRefreshToken?.(refreshToken)
	if (roleCookies !== undefined) setRole?.(roleCookies)

	return (
		<Routes>
			{routes
				.filter((item) => item.role.includes(role as RoleUser))
				.map((route: any) => (
					<Route path={route.path} element={<route.element />} key={nanoid()} />
				))}
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default withLayout(App)
