import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Heading } from '@/components'

import styles from './Title.module.scss'

export const Title: FC<{
	title: string
}> = ({ title }): JSX.Element => {
	const navigate = useNavigate()

	return (
		<div className={styles.title}>
			<div className={styles.btns}>
				<Button onClick={() => navigate('/')}>На главную</Button>
				<Button onClick={() => navigate(-1)}>Назад</Button>
			</div>
			<Heading title={title} />
		</div>
	)
}
