import { addDays, format } from 'date-fns'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Advertisements,
	Button,
	FieldInput,
	FieldSelect,
	Input,
	Loader,
	Textarea,
	Title,
} from '@/components'

import { useAuth } from '@/hooks'

import {
	DictionariesService,
	EmployeesService,
	RequestsService,
	UserService,
} from '@/services'

import { isDisable, modalStyle } from '@/settings'

import { IAddWorks, ISelect } from '@/types'

import styles from './Form.module.scss'
import { CommentModal } from './modal'

type TAddWorksForm = IAddWorks & {
	counterpartyId?: string
}

export const RequestNewWork = (): JSX.Element => {
	const navigate = useNavigate()
	const { role } = useAuth()
	const { id } = useParams()
	const { state } = useLocation()

	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [types, setTypes] = useState<ISelect[]>([])
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)

	const [radioNoValue, setRadioNoValue] = useState(true)

	const [counterparty, setCounterparty] = useState<ISelect[]>()
	const [users, setUsers] = useState<ISelect[]>([])

	const fetch = async (id?: string) => {
		setIsLoad(true)

		if (role === 'User') {
			const users = await EmployeesService.getUser()
			setUsers(
				users?.map((item) => {
					return {
						label: item.name,
						value: item.id,
					}
				})
			)
		} else if (role === 'Admin' || role === 'Manager') {
			const data = await UserService.getUserAll()
			setCounterparty(
				data
					.filter((item) => item.counterparty !== null)
					.map((item) => {
						return {
							label: item.counterparty.name,
							value: item.counterparty.id,
						}
					})
			)
			if (state?.req) {
				const users = await UserService.getUserCounterpartyView({
					CounterpartyId: state?.req?.counterparty?.id,
				})
				setUsers(
					users?.employees.map((item) => {
						return {
							label: item.name,
							value: item.id,
						}
					})
				)
			}
		} else {
			const users = await UserService.getUserCounterpartyView({ UserId: id })
			setUsers(
				users?.employees.map((item: any) => {
					return {
						label: item.name ? item.name : item.userName,
						value: item.id,
					}
				})
			)
		}
		const type = await DictionariesService.workTypes()
		setTypes(type)
		setIsLoad(false)
	}

	const handleRadio = (e: any) => {
		setRadioNoValue(!radioNoValue)
	}

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isDirty, isValid },
	} = useForm<TAddWorksForm>({
		mode: 'onChange',
		defaultValues: async () => {
			if (id) {
				const data = await RequestsService.getInfo(id)

				setRadioNoValue(data?.isConstruction)

				!isDisable(state?.req?.status, role)
					? fetch(data?.requestCreator?.id)
					: setIsLoad(false)

				return {
					...data,
					worksIdList: data?.worksList?.map((item) => {
						return { label: item?.name, value: item?.id }
					}),
					employeesList: data?.employeesList?.map((item) => {
						return { label: item.name, value: item.id }
					}),
					employeesMainContactId: {
						label: data?.safetyContact?.name,
						value: data?.safetyContact?.id,
					},
					safetyContactId: {
						label: data?.employeesMainContact?.name,
						value: data?.employeesMainContact?.id,
					},
					counterpartyId: {
						label: data?.counterparty?.name,
						value: data?.counterparty?.id,
					},
					comment: data?.comment,
					check: true,
				}
			} else {
				fetch()
			}
		},
	})

	const startDateWatch = watch('startDate')
	const counterpartyIdWatch = watch('counterpartyId')
	const guardCommentWatch = watch('guardComment')
	const renterCommentWatch = watch('renterComment')
	const worksIdListWatch = watch('worksIdList')

	const onSubmit: SubmitHandler<any> = async (body) => {
		if (state?.req?.status?.name === 'На рассмотрении') {
			RequestsService.editComment({
				RequestId: id,
				comment: body?.comment,
				navigate,
				role,
			})
		} else {
			if (id) {
				await RequestsService.editWorks(
					{
						...body,
						startTime:
							state?.req?.startTime === body.startTime
								? state?.req?.startTime
								: `${body.startTime}:00`,
						endTime:
							state?.req?.endTime === body.endTime
								? state?.req?.endTime
								: `${body.endTime}:00`,
						isConstruction: radioNoValue,
						worksIdList: Array.isArray(body.worksIdList)
							? body.worksIdList.map((item: any) => {
									return item.value
								})
							: body.worksIdList,
						employeesIdList: Array.isArray(body.employeesList)
							? body.employeesList.map((item: any) => {
									return item.value
								})
							: body.employeesList,
						employeesMainContactId: body.employeesMainContactId.value,
						safetyContactId: body.safetyContactId.value,
						id: id,
					},
					navigate,
					role
				)
			} else {
				if (role === 'User')
					await RequestsService.addWorks(
						{
							...body,
							startTime: `${body.startTime}:00`,
							endTime: `${body.endTime}:00`,
							isConstruction: radioNoValue,
							worksIdList: Array.isArray(body.worksIdList)
								? body.worksIdList.map((item: any) => {
										return item.value
									})
								: body.worksIdList.value,
							employeesIdList: Array.isArray(body.employeesList)
								? body.employeesList.map((item: any) => {
										return item.value
									})
								: [body.employeesList.value],
							employeesMainContactId: body.employeesMainContactId.value,
							safetyContactId: body.safetyContactId.value,
						},
						navigate,
						role
					)
				else
					await RequestsService.addWorks(
						{
							...body,
							startTime: `${body.startTime}:00`,
							endTime: `${body.endTime}:00`,
							isConstruction: radioNoValue,
							worksIdList: Array.isArray(body.worksIdList)
								? body.worksIdList.map((item: any) => {
										return item.value
									})
								: body.worksIdList.value,
							employeesIdList: Array.isArray(body.employeesList)
								? body.employeesList.map((item: any) => {
										return item.value
									})
								: [body.employeesList.value],
							employeesMainContactId: body.employeesMainContactId.value,
							safetyContactId: body.safetyContactId.value,
						},
						navigate,
						role,
						body.counterpartyId.value
					)
			}
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={
					id ? 'Заявка на выполнение работ' : 'Новая заявка на выполнение работ'
				}
			/>
			<Advertisements position={3} />
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				<div className={styles.radioBlock}>
					<p>Вид работ</p>
					<label htmlFor="radioYes">
						<Input
							type="radio"
							name="radiob"
							disabled={isDisable(state?.req?.status, role)}
							id="radioYes"
							checked={radioNoValue}
							onChange={handleRadio}
						/>
						Строительные
					</label>
					<label htmlFor="radioNo">
						<Input
							type="radio"
							name="radiob"
							disabled={isDisable(state?.req?.status, role)}
							id="radioNo"
							checked={!radioNoValue}
							onChange={handleRadio}
						/>
						Не строительные
					</label>
				</div>
				{!radioNoValue ? (
					<></>
				) : (
					<div className={styles.info}>
						<p className={styles.red}>
							Внимание! При отсутствии предварительного согласования данных
							работ технической службой ТРК заявка может быть отклонена!
						</p>
					</div>
				)}
				<Textarea
					disabled={isDisable(state?.req?.status, role)}
					placeholder="Название сторонней организации или фио физического лица с паспортными данными с паспортными данными"
					{...register('sideCounterparty')}
					error={errors.sideCounterparty}
					rows={3}
				/>
				<FieldInput
					type="date"
					placeholder="Дата начала действия заявки"
					min={format(
						startDateWatch ? new Date(startDateWatch) : new Date(),
						'yyyy-MM-dd'
					)}
					max={format(
						startDateWatch ? new Date(startDateWatch) : addDays(new Date(), 14),
						'yyyy-MM-dd'
					)}
					disabled={isDisable(state?.req?.status, role)}
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
					required
				/>
				{startDateWatch && (
					<FieldInput
						type="date"
						placeholder="Дата завершения действия заявки"
						min={format(new Date(startDateWatch), 'yyyy-MM-dd')}
						max={format(addDays(new Date(startDateWatch), 14), 'yyyy-MM-dd')}
						disabled={isDisable(state?.req?.status, role)}
						{...register('endDate', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.endDate}
						required
					/>
				)}
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время начала"
					{...register('startTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startTime}
					required
				/>
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="time"
					placeholder="Время завершения"
					{...register('endTime', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.endTime}
					required
				/>
				<FieldSelect
					disabled={isDisable(state?.req?.status, role)}
					id="worksIdList"
					placeholder="Вид работ"
					control={control}
					options={types}
					rules={{
						required: 'Поле обязательно к заполнению!',
					}}
					error={errors.worksIdList}
					isMulti
					required
				/>
				{worksIdListWatch?.filter((item) => item.label === 'Другие работы')
					.length ? (
					<FieldInput
						disabled={isDisable(state?.req?.status, role)}
						placeholder="Другие работы"
						{...register('additionalWorks', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.additionalWorks}
						required
					/>
				) : null}
				<FieldInput
					disabled={isDisable(state?.req?.status, role)}
					type="number"
					min={0}
					placeholder="Количество человек"
					{...register('employeesAmount', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.employeesAmount}
					required
				/>
				{role === 'Admin' || role === 'Manager' ? (
					<>
						<FieldSelect
							id="counterpartyId"
							placeholder="Магазин"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							error={errors.counterpartyId}
							options={counterparty}
							changeProps={async (value) => {
								const data = await UserService.getUserCounterpartyView({
									CounterpartyId: value.value.toString(),
								})
								setUsers(
									data?.employees.map((item) => {
										return {
											label: item.name,
											value: item.id,
										}
									})
								)
							}}
							disabled={state?.req}
							required
						/>
						{counterpartyIdWatch && (
							<>
								<FieldSelect
									id="employeesList"
									placeholder="ФИО сотрудников"
									control={control}
									rules={{
										required: 'Поле обязательно к заполнению!',
									}}
									options={users}
									error={errors.employeesList}
									disabled={isDisable(state?.req?.status, role)}
									isMulti
									required
								/>
								<FieldSelect
									id="employeesMainContactId"
									placeholder="ФИО ответственного за технику безопасности и противопожарную безопасность"
									control={control}
									rules={{
										required: 'Поле обязательно к заполнению!',
									}}
									options={users}
									error={errors.employeesMainContactId}
									disabled={isDisable(state?.req?.status, role)}
									required
								/>
								<FieldSelect
									id="safetyContactId"
									placeholder="ФИО ответственного за проведение работ"
									control={control}
									rules={{
										required: 'Поле обязательно к заполнению!',
									}}
									options={users}
									error={errors.safetyContactId}
									disabled={isDisable(state?.req?.status, role)}
									required
								/>
							</>
						)}
					</>
				) : (
					<>
						<FieldSelect
							id="employeesList"
							placeholder="ФИО сотрудников"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.employeesList}
							disabled={isDisable(state?.req?.status, role)}
							isMulti
							required
						/>
						<FieldSelect
							id="employeesMainContactId"
							placeholder="ФИО ответственного за технику безопасности и противопожарную безопасность"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.employeesMainContactId}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
						<FieldSelect
							id="safetyContactId"
							placeholder="ФИО ответственного за проведение работ"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							options={users}
							error={errors.safetyContactId}
							disabled={isDisable(state?.req?.status, role)}
							required
						/>
					</>
				)}
				<Textarea
					placeholder="Комментарий"
					{...register('comment')}
					disabled={
						state?.req?.status?.name === 'На рассмотрении'
							? false
							: isDisable(state?.req?.status, role)
					}
					rows={3}
				/>
				{(role === 'Manager' || role === 'Admin') &&
				state?.req?.mainContactPhoneNumber ? (
					<div>
						<p>
							Номер телефона ответственного за проведение работ:{' '}
							<Link to={`tel:${state?.req?.mainContactPhoneNumber}`}>
								{state?.req?.mainContactPhoneNumber}
							</Link>
						</p>
					</div>
				) : null}
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.phoneEmergency ? (
					<div>
						<p>
							Номер телефона ответственного за ЧС:{' '}
							<Link to={`tel:${state?.req?.phoneEmergency}`}>
								{state?.req?.phoneEmergency}
							</Link>
						</p>
					</div>
				) : null}
				{role === 'User'
					? renterCommentWatch && (
							<p>Комментарий для арендатора: {renterCommentWatch}</p>
						)
					: role === 'Admin' || role === 'Manager' || role === 'Marketer'
						? (guardCommentWatch || renterCommentWatch) && (
								<div>
									{guardCommentWatch && (
										<p>Комментарий для охраны: {guardCommentWatch}</p>
									)}
									{renterCommentWatch && (
										<p>Комментарий для арендатора: {renterCommentWatch}</p>
									)}
								</div>
							)
						: role === 'Guard' &&
							guardCommentWatch && (
								<p>Комментарий для охраны: {guardCommentWatch}</p>
							)}
				{state?.req ? (
					role === 'User' ? (
						<>
							{state?.req?.status?.name === 'На рассмотрении' && (
								<Button>Изменить заявку</Button>
							)}
							{state?.req?.status?.name !== 'Аннулировано' ?? (
								<div>
									<Button>Изменить комментарий</Button>
								</div>
							)}
						</>
					) : role === 'Admin' || role === 'Manager' || role === 'Marketer' ? (
						<div className={styles.btns}>
							{!isDisable(state?.req?.status, role) ? (
								<Button>Изменить заявку</Button>
							) : (
								<Button
									appearance="red"
									onClick={async (e) => {
										e.preventDefault()
										await RequestsService.decline(id, () => navigate(-1))
									}}
								>
									Аннулировать
								</Button>
							)}
							<Button
								onClick={(e) => {
									e.preventDefault()
									setIsOpen(true)
								}}
							>
								Добавить комментарий
							</Button>
						</div>
					) : null
				) : (
					<Button disabled={!isDirty || !isValid}>Отправить заявку</Button>
				)}
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<CommentModal
					id={id}
					comment={{
						renterComment: renterCommentWatch,
						guardComment: guardCommentWatch,
					}}
					close={() => setIsOpen(false)}
				/>
			</Modal>
		</>
	)
}
