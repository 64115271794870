import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { UserService } from 'services/user.service'

import { Button, FieldInput, SubHeading } from '@/components'

import styles from './Modal.module.scss'

interface IForm {
	phone: string
	email: string
	name: string
	counterpartyName: string
	itn: string
}

export const ChangeInfoModal: FC<{
	id: string
	close: () => void
	update: () => void
}> = ({ id, close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IForm>({
		mode: 'onChange',
		defaultValues: async () => {
			const user = await UserService.getUserCounterpartyView({
				UserId: id,
			})

			return {
				counterpartyName: user.legalName,
				email: user.user.email,
				phone: user.user.phoneNumber,
				itn: user.itn,
				name: user.user.userName,
			}
		},
	})

	const onSubmit: SubmitHandler<IForm> = async (body) =>
		await UserService.changeInfoAdmin(
			{
				...body,
				userId: id,
			},
			close,
			update
		)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить почту" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="ФИО"
					{...register('name', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.name}
					required
				/>
				<FieldInput
					placeholder="Телефон"
					{...register('phone', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.phone}
					required
				/>
				<FieldInput
					placeholder="Почта"
					type="email"
					{...register('email', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.email}
					required
				/>
				<FieldInput
					placeholder="Название организации"
					{...register('counterpartyName', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.counterpartyName}
					required
				/>
				<FieldInput
					placeholder="ИНН"
					{...register('itn', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.itn}
					required
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
