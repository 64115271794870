import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'

import { Button, FieldInput, SubHeading } from '@/components'

import { UserService } from '@/services'

import { IChangeEmail } from '@/types'

import styles from './Modal.module.scss'

export const ChangeUserMailModal: FC<{
	close: () => void
	update: () => void
}> = ({ close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IChangeEmail>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IChangeEmail> = async (body) =>
		await UserService.changeEmail(body, close, update)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить почту" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Пароль"
					type="password"
					{...register('password', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.password}
				/>
				<FieldInput
					placeholder="Новая почта"
					type="email"
					{...register('email', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.email}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
