import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { modalStyle } from 'settings/modalStyle.config'

import { Button, FieldInput, Heading } from '@/components'

import { AuthService } from '@/services'

import { IAuthBody } from '@/types'

import { RestorePasswordModal } from './RestorePasswordModal'

export const AuthForm: FC<{ className: string }> = ({ className }) => {
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<IAuthBody>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IAuthBody> = async (body) => {
		clearErrors()
		await AuthService.auth(body, setError)
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} className={className}>
				<Heading title="Авторизация" />
				<FieldInput
					{...register('email', {
						required: 'Логин обязательный!',
					})}
					type="email"
					placeholder="Почта"
					error={errors.email}
				/>
				<FieldInput
					{...register('password', {
						required: 'Пароль обязательный!',
					})}
					type="password"
					placeholder="Пароль"
					error={errors.password}
				/>
				<Button appearance="white">Вход</Button>
				<p
					className="text-center italic mt-2 cursor-pointer text-gray-600 mx-auto"
					onClick={() => setIsOpen(true)}
				>
					Восстановить пароль
				</p>
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<RestorePasswordModal close={() => setIsOpen(false)} />
			</Modal>
		</>
	)
}
