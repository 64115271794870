import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'

import { Button, FieldInput, SubHeading } from '@/components'

import { IImportExport } from '../ImportExport'

import styles from './Modal.module.scss'

export const CarModal: FC<{
	arr: any
	setArr: (arr: any) => void
	close: () => void
}> = ({ arr, close, setArr }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IImportExport>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<IImportExport> = async (body) => {
		setArr([...arr, body])
		close()
	}

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Добавить автомобиля" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Марка автомобиля"
					{...register('name', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.name}
					required
				/>
				<FieldInput
					placeholder="Номер автомобиля"
					{...register('number', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.number}
					required
				/>
				<Button>Добавить</Button>
			</form>
		</div>
	)
}
