import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from '@/components'

import { ModalType } from '@/pages'

import { IEmployeeUser } from '@/types'

import styles from './Employees.module.scss'

const columnHelperEmployees = createColumnHelper<
	IEmployeeUser & { btns?: any }
>()

export const employeesColumns = (
	open: (type: ModalType, employee: IEmployeeUser) => void
) => [
	columnHelperEmployees.accessor('name', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО</span>,
	}),
	columnHelperEmployees.accessor('position', {
		size: 'auto' as unknown as number,
		header: () => <span>Должность</span>,
	}),
	columnHelperEmployees.accessor('phoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Телефон</span>,
		cell: ({ row }) => (
			<Link to={`tell:${row.original.phoneNumber}`}>
				{row.original.phoneNumber}
			</Link>
		),
	}),
	columnHelperEmployees.accessor('passageStatus', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус</span>,
	}),
	columnHelperEmployees.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button
					className="cursor-pointer mx-auto"
					onClick={() => open('control', row.original)}
				>
					Управление
				</Button>
			</div>
		),
	}),
]
