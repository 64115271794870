import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { AuthService } from 'services/auth.service'

import { Button, FieldInput, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const ChangePasswordModal: FC<{
	email: string
	close: () => void
	update: () => void
}> = ({ email, close, update }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ newPassword: string }>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<{
		newPassword: string
	}> = async ({ newPassword }) =>
		await AuthService.resetPassword({ newPassword, email }, close, update)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Сменить пароль" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Новый пароль"
					type="password"
					{...register('newPassword', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.newPassword}
				/>
				<Button>Сменить</Button>
			</form>
		</div>
	)
}
