import cn from 'clsx'
import { FC, memo } from 'react'
import {
	Control,
	Controller,
	FieldError,
	RegisterOptions,
} from 'react-hook-form'
import Select from 'react-select'

import { selectMyStyles } from '@/settings'

import styles from './Field.module.scss'

export interface ISelectField {
	id: string
	placeholder: string
	control: Control<any>
	rules?:
		| Omit<
				RegisterOptions<any, string>,
				'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
		  >
		| undefined
	options?: ISelect[]
	error?: FieldError | any
	isMulti?: boolean
	required?: boolean
	disabled?: boolean
	changeProps?: (value: ISelect) => void
}

interface ISelect {
	label: string
	value: string | number
}

export const FieldSelect: FC<ISelectField> = memo(
	({
		id,
		placeholder,
		control,
		rules,
		options,
		error,
		changeProps,
		isMulti = false,
		required = false,
		disabled,
	}) => {
		return (
			<div
				className={cn(styles.common, styles.select, {
					[styles.error]: error,
				})}
			>
				<label htmlFor={id} className={styles.select}>
					<span
						className={cn({
							[styles.required]: required,
						})}
					>
						{placeholder}
					</span>
					<Controller
						control={control}
						name={id}
						rules={rules}
						render={({ field: { ref, onChange, value } }) => (
							<Select
								placeholder={''}
								instanceId={id}
								inputId={id}
								ref={ref}
								noOptionsMessage={() => 'Данных нет'}
								onChange={(value) => {
									onChange(value)
									changeProps && changeProps(value)
								}}
								defaultValue={value}
								options={options ? options : value}
								styles={selectMyStyles}
								isMulti={isMulti}
								required={required}
								isDisabled={disabled}
							/>
						)}
					/>
				</label>
				{error && (
					<p>
						<>{error.message}</>
					</p>
				)}
			</div>
		)
	}
)

FieldSelect.displayName = 'Select'
