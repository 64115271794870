import { isPassStatus } from 'pages/Request'
import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import { IEmployeeUser, IEmployees, ISelect } from '../types'

const getEmployeesUrl = (url: string) => `/employees/${url}`

export const EmployeesService = {
	async getUser(): Promise<IEmployeeUser[]> {
		try {
			const { data } = await axiosPrivate.get<IEmployees>(
				getEmployeesUrl('get_employees')
			)
			return data.employees
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async getUserBySelect(): Promise<ISelect[]> {
		try {
			const { data } = await axiosPrivate.get<IEmployees>(
				getEmployeesUrl('get_employees')
			)
			return data.employees
				.filter((item) => !isPassStatus(item))
				.map((item: any) => {
					return {
						label: item.name ? item.name : item.userName,
						value: item.id,
					}
				})
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async add(
		body: { name: string; position: string; phoneNumber: string },
		fun: () => void
	) {
		try {
			await axiosPrivate.post<any>(getEmployeesUrl('add'), body)
			toast.success('Сотрудник успешно добавлен')
			fun()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async edit(
		id: string,
		body: { name: string; position: string; phoneNumber: string },
		fun: () => void
	) {
		try {
			await axiosPrivate.patch<any>(
				getEmployeesUrl(`edit?EmployeeId=${id}`),
				body
			)
			toast.success('Данные изменены')
			fun()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async switchActive(id: string): Promise<number> {
		try {
			const { status } = await axiosPrivate.patch<any>(
				getEmployeesUrl(`switch_active?EmployeeId=${id}`)
			)
			return status
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async delete(
		id: string,
		update: () => void,
		close: () => void
	): Promise<void> {
		try {
			await axiosPrivate.delete<any>(getEmployeesUrl(`delete?EmployeeId=${id}`))
			toast.success('Сотрудник удален')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
