export const getType = (item: any) => {
	if (
		item.type === 'Внос/вынос ТМЦ, без рампы' ||
		item.type === 'Внос/вынос ТМЦ, через рампу'
	) {
		return `/request/${item.id}/import-export`
	}
	if (item.type === 'Проведение промо, фото/видеосъёмки') {
		return `/request/${item.id}/promo`
	}
	if (item.type === 'Выполнение работ') {
		return `/request/${item.id}/work`
	} else {
		return `/request/${item.id}/pass`
	}
}
