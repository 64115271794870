import cn from 'clsx'
import { TextareaHTMLAttributes, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'

import styles from './Textarea.module.scss'

export interface ITextAreaProps
	extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	placeholder: string
	error?: FieldError
	required?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
	({ placeholder, error, style, className, required, ...rest }, ref) => {
		return (
			<div
				className={cn(styles.common, className, styles.field, {
					[styles.error]: error,
				})}
				style={style}
			>
				<label>
					<span
						className={cn({
							[styles.required]: required,
						})}
					>
						{placeholder}
					</span>
					<TextareaAutosize
						className={cn(styles.textarea, 'max-w-full')}
						ref={ref}
						{...rest}
					/>
				</label>
				{error && <p>{error.message}</p>}
			</div>
		)
	}
)
