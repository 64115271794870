import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { RequestsService } from 'services/Requests.service'

import { Button, SubHeading } from '@/components'

import styles from './Modal.module.scss'

export const StatusModal: FC<{
	id: string
	close: () => void
}> = ({ id, close }) => {
	const approve = async () => await RequestsService.approve(id, close)
	const decline = async () => await RequestsService.decline(id, close)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Смена статуса" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body)}>
				<Button onClick={approve}>Принять</Button>
				<Button onClick={decline} appearance="red">
					Аннулировать
				</Button>
			</div>
		</div>
	)
}
