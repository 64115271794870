import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'

import { Button, SubHeading } from '@/components'

import { TypeModal } from '../UserInfo'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	open: (type: TypeModal) => void
	close: () => void
}> = ({ open, close }) => {
	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Управление" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<Button onClick={() => open('email')}>Сменить почту</Button>
				<Button onClick={() => open('phone')}>Сменить номер телефона</Button>
				<Button onClick={() => open('password')}>Сменить пароль</Button>
			</div>
		</div>
	)
}
