export const isDisable = (
	status: { id: string; name: string },
	role: string
): boolean => {
	switch (status?.name) {
		case 'Принято':
			return true
		case 'На рассмотрении':
			if (role === 'User') return true
			else return false
		case 'Отменено пользователем':
			return true
		case 'Аннулировано':
			return true
		default:
			return false
	}
}
