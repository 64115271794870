import cn from 'clsx'
import { FC } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AnnouncementsService } from 'services/Announcements.service'

import { Button, SubHeading } from '@/components'

import { IAnnouncements } from '@/types'

import styles from './Modal.module.scss'

export const ChoiceModal: FC<{
	announcement: IAnnouncements
	close: () => void
	update: () => void
}> = ({ announcement, close, update }) => {
	const navigate = useNavigate()

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Управление" />
				<MdClose size={20} onClick={close} />
			</div>
			<div className={cn(styles.body, styles.choice)}>
				<Button
					appearance="white"
					onClick={() =>
						navigate(`/announcement/edit/${announcement.id}`, {
							state: {
								item: announcement,
							},
						})
					}
				>
					Редактировать
				</Button>
				<Button
					appearance={announcement.isActive ? 'red' : 'white'}
					onClick={() =>
						AnnouncementsService.switchIsActive(announcement.id, update, close)
					}
				>
					{announcement.isActive ? 'Деактивировать' : 'Активировать'}
				</Button>
				<Button
					appearance="red"
					onClick={() =>
						AnnouncementsService.delete(announcement.id, update, close)
					}
				>
					Удалить
				</Button>
			</div>
		</div>
	)
}
