import { FC, useEffect, useState } from 'react'

import { Button, Loader } from '@/components'

import { useAuth } from '@/hooks'

import { AnnouncementsService } from '@/services'

import { IAnnouncements } from '@/types'

import styles from './Advertisements.module.scss'

export const Advertisements: FC<{ position?: number }> = ({
	position = 1,
}): JSX.Element => {
	const { role } = useAuth()

	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [advertisements, setAdvertisements] = useState<IAnnouncements[]>([])

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const data = await AnnouncementsService.getAll(true)
		setAdvertisements(data)
		setIsLoad(false)
	}
	if (isLoad) {
		return <Loader />
	}

	return (
		role === 'User' && (
			<div className={styles.advertisements}>
				{advertisements
					.filter((item) => item.position === position)
					.map((adv) => (
						<div key={adv.id} className={styles.adv}>
							<p className={styles.date}>
								{new Date(adv.startDate).toLocaleDateString()}
							</p>
							<p className={styles.text}>{adv.text}</p>
							{adv.attachments.length > 0 && (
								<>
									<p>Файлы:</p>
									<div className="flex flex-row flex-wrap">
										{adv.attachments.map((attachment) => (
											<Button
												key={attachment.id}
												className="w-min"
												onClick={async () => {
													const data =
														await AnnouncementsService.getAttachmentLink(
															attachment.id
														)

													window.open(data.link)
												}}
											>
												{attachment.name}
											</Button>
										))}
									</div>
								</>
							)}
						</div>
					))}
			</div>
		)
	)
}
