import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Loader, SubHeading, Table, Title } from '@/components'

import { RequestsService } from '@/services'

import { getType } from '@/settings'

import { IInfo } from '@/types'

import styles from './List.module.scss'
import { userColumns } from './Table.config'

export const ShopRequestsList = (): JSX.Element => {
	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [requestsData, setRequestsData] = useState<IInfo[]>([])
	const props = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const data = await RequestsService.getAll({
			CounterpartyName: props?.shopName,
		})
		setRequestsData(data)
		setIsLoad(false)
	}

	const onClick = (item: any) => {
		navigate(getType(item), {
			state: {
				req: item,
			},
		})
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<div className={styles.wrapper}>
			<Title title="Заявки по магазину" />
			{requestsData?.length ? (
				<div className={styles.table}>
					<Table dataProps={requestsData} columns={userColumns(onClick)} />
				</div>
			) : (
				<SubHeading title="Данных нет" />
			)}
		</div>
	)
}
