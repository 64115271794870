import { createColumnHelper } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import { PermitsService } from 'services/Permits.service'

import { Button } from '@/components'

import { IInfo, IPermit } from '@/types'

import styles from './Pass.module.scss'

const columnHelper = createColumnHelper<IPermit & { btns?: any }>()

export const columnPass = (role: string, update: () => void) => [
	columnHelper.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер</span>,
	}),
	columnHelper.accessor('employee.counterparty.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Магазин</span>,
	}),
	columnHelper.accessor('employee.name', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО</span>,
	}),
	columnHelper.accessor('employee.phoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original?.employee?.phoneNumber}`}>
				{row.original?.employee?.phoneNumber}
			</Link>
		),
	}),
	columnHelper.accessor('isActive', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус</span>,
		cell: ({ row }) => (
			<span>
				{(row.original.isActive as unknown as string) === 'Получен'
					? 'Получен'
					: 'Аннулирован'}
			</span>
		),
	}),
	columnHelper.accessor('guardComment', {
		size: 'auto' as unknown as number,
		header: () => <span>Комментарий для охраны</span>,
	}),
	columnHelper.accessor('renterComment', {
		size: 'auto' as unknown as number,
		header: () => <span>Комментарий для арендатора</span>,
	}),
	columnHelper.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				{(row.original.isActive as unknown as string) === 'Получен' ? (
					<Button
						appearance="red"
						onClick={async () => PermitsService.cancel(row.original.id, update)}
					>
						Аннулировать
					</Button>
				) : (
					<Button
						onClick={async () =>
							PermitsService.setActive(row.original.id, update)
						}
					>
						Восстановить
					</Button>
				)}
			</div>
		),
	}),
]

const columnHelperRequests = createColumnHelper<IInfo & { btns?: any }>()

export const requestColumns = (goTo: (url: any) => void) => [
	columnHelperRequests.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер заявки</span>,
	}),
	columnHelperRequests.accessor('startDate', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата пропуска</span>,
		cell: ({ row }) =>
			`${new Date(row.original.startDate).toLocaleDateString()} - ${new Date(
				row.original.endDate
			).toLocaleDateString()}`,
	}),
	columnHelperRequests.accessor('counterparty.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Магазин</span>,
	}),
	columnHelperRequests.accessor('mainContactName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО сотрудника</span>,
	}),
	columnHelperRequests.accessor('mainContactPhoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона сотрудник</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.mainContactPhoneNumber}`}>
				{row.original.mainContactPhoneNumber}
			</Link>
		),
	}),
	columnHelper.accessor('guardComment', {
		size: 'auto' as unknown as number,
		header: () => <span>Комментарий для охраны</span>,
	}),
	columnHelperRequests.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => goTo(row.original)}>Подробнее</Button>
			</div>
		),
	}),
]
