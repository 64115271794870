import { createColumnHelper } from '@tanstack/react-table'

import { Button } from '@/components'

import { IAnnouncements } from '@/types'

import styles from './List.module.scss'

type TAnnouncements = IAnnouncements & {
	btns?: string
	isActiveStr: string
	startDateStr: string
	endDateStr: string
}

const columnHelper = createColumnHelper<TAnnouncements>()

export const columns = (openModal: (announcement: IAnnouncements) => void) => [
	columnHelper.accessor('startDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата начала</span>,
	}),
	columnHelper.accessor('endDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата завершения</span>,
	}),
	columnHelper.accessor('position', {
		size: 'auto' as unknown as number,
		header: () => <span>Место объявления</span>,
	}),
	columnHelper.accessor('text', {
		size: 'auto' as unknown as number,
		header: () => <span>Текст объявления</span>,
	}),
	columnHelper.accessor('isActiveStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Статус объявления</span>,
	}),
	columnHelper.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button appearance="white" onClick={() => openModal(row.original)}>
					Управление
				</Button>
			</div>
		),
	}),
]
