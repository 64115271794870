import { createColumnHelper } from '@tanstack/table-core'
import { Link } from 'react-router-dom'
import { IInfo } from 'types/Request.type'

import { Button } from '@/components'

import styles from '../../Table.module.scss'

const columnHelperRequests = createColumnHelper<
	IInfo & { btns?: any; startDateStr: string; endDateStr: string }
>()

export const requestColumns = (
	goTo: (url: any) => void,
	openModal: (id: string) => void
) => [
	columnHelperRequests.accessor('identifier', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер заявки</span>,
	}),
	columnHelperRequests.accessor('startDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата начала</span>,
	}),
	columnHelperRequests.accessor('endDateStr', {
		size: 'auto' as unknown as number,
		header: () => <span>Дата завершения</span>,
	}),
	columnHelperRequests.accessor('type', {
		size: 'auto' as unknown as number,
		header: () => <span>Тип заявки</span>,
		meta: {
			filterVariant: 'select',
		},
	}),
	columnHelperRequests.accessor('counterparty.name', {
		size: 'auto' as unknown as number,
		header: () => <span>Магазин</span>,
	}),
	columnHelperRequests.accessor('mainContactName', {
		size: 'auto' as unknown as number,
		header: () => <span>ФИО сотрудника</span>,
	}),
	columnHelperRequests.accessor('mainContactPhoneNumber', {
		size: 'auto' as unknown as number,
		header: () => <span>Номер телефона сотрудник</span>,
		cell: ({ row }) => (
			<Link to={`tel:${row.original.mainContactPhoneNumber}`}>
				{row.original.mainContactPhoneNumber}
			</Link>
		),
	}),
	columnHelperRequests.accessor('btns', {
		size: 'auto' as unknown as number,
		header: () => <span>Управление</span>,
		cell: ({ row }) => (
			<div className={styles.btns}>
				<Button onClick={() => goTo(row.original)}>Подробнее</Button>
				<Button onClick={() => openModal(row.original.id)}>
					Сменить статус
				</Button>
			</div>
		),
	}),
]
