import { AdministrationList } from 'pages/Administration'

import {
	ActiveList,
	AnnouncementsForm,
	AnnouncementsList,
	ArchiveList,
	Auth,
	Employees,
	ListRequest,
	Pass,
	RequestList,
	RequestNewImportExport,
	RequestNewPass,
	RequestNewPromo,
	RequestNewWork,
	ShopList,
	ShopRequestsList,
	User,
} from '@/pages'

export interface RoutesInterface {
	path: string
	element: () => JSX.Element
	role: RoleUser[]
}

export type RoleUser =
	| 'User'
	| 'Manager'
	| 'Guard'
	| 'Dispatcher'
	| 'Marketer'
	| 'Admin'
	| ''

export const routes: RoutesInterface[] = [
	{
		path: '/',
		element: Auth,
		role: [''],
	},
	{
		path: '/',
		element: User,
		role: ['User', 'Manager', 'Guard', 'Dispatcher', 'Marketer', 'Admin'],
	},
	{
		path: '/request/list',
		element: RequestList,
		role: ['User'],
	},
	{
		path: '/request/:id/pass',
		element: RequestNewPass,
		role: ['User', 'Manager', 'Marketer', 'Admin', 'Guard', 'Dispatcher'],
	},
	{
		path: '/request/:id/promo',
		element: RequestNewPromo,
		role: ['User', 'Manager', 'Marketer', 'Admin', 'Guard', 'Dispatcher'],
	},
	{
		path: '/request/:id/import-export',
		element: RequestNewImportExport,
		role: ['User', 'Manager', 'Marketer', 'Admin', 'Guard', 'Dispatcher'],
	},
	{
		path: '/request/:id/work',
		element: RequestNewWork,
		role: ['User', 'Manager', 'Marketer', 'Admin', 'Guard', 'Dispatcher'],
	},
	{
		path: '/request/active/list',
		element: ActiveList,
		role: ['User', 'Manager', 'Marketer', 'Admin', 'Guard', 'Dispatcher'],
	},
	{
		path: '/request/archive/list',
		element: ArchiveList,
		role: ['Manager', 'Marketer', 'Admin', 'Guard'],
	},
	{
		path: '/request/new/list',
		element: ListRequest,
		role: ['Manager', 'Marketer', 'Admin'],
	},
	{
		path: '/request/new/pass',
		element: RequestNewPass,
		role: ['User', 'Admin', 'Manager'],
	},
	{
		path: '/request/new/promo',
		element: RequestNewPromo,
		role: ['User', 'Admin', 'Manager', 'Marketer'],
	},
	{
		path: '/request/new/import-export',
		element: RequestNewImportExport,
		role: ['User', 'Admin', 'Manager'],
	},
	{
		path: '/request/new/work',
		element: RequestNewWork,
		role: ['User', 'Admin', 'Manager'],
	},
	{
		path: '/pass',
		element: Pass,
		role: ['User', 'Manager', 'Guard', 'Dispatcher', 'Marketer', 'Admin'],
	},
	{
		path: '/shop/list',
		element: ShopList,
		role: ['Manager', 'Admin', 'Guard'],
	},
	{
		path: '/shop/:shopName/requests',
		element: ShopRequestsList,
		role: ['Manager', 'Admin', 'Guard'],
	},
	{
		path: '/shop/:shopId/employees',
		element: Employees,
		role: ['Manager', 'Admin', 'Guard'],
	},
	{
		path: '/announcement/list',
		element: AnnouncementsList,
		role: ['Manager', 'Admin'],
	},
	{
		path: '/announcement/add',
		element: AnnouncementsForm,
		role: ['Manager', 'Admin'],
	},
	{
		path: '/announcement/edit/:id',
		element: AnnouncementsForm,
		role: ['Manager', 'Admin'],
	},
	{
		path: '/employees',
		element: Employees,
		role: ['User'],
	},
	{
		path: '/administration/list',
		element: AdministrationList,
		role: ['Admin', 'Manager'],
	},
]
