import cn from 'clsx'
import { DetailedHTMLProps, HtmlHTMLAttributes } from 'react'

import styles from './Loader.module.scss'

interface LoaderProps
	extends DetailedHTMLProps<
		HtmlHTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	> {}
export const Loader = ({ className }: LoaderProps): JSX.Element => {
	return (
		<div className={cn(styles.loader, className)}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}
