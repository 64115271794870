import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Loader, SubHeading, Table, Title } from '@/components'

import { useAuth } from '@/hooks'

import { RequestsService } from '@/services'

import { getType } from '@/settings'

import { IInfo } from '@/types'

import styles from '../Table.module.scss'

import { archiveRequestColumns } from './Table.config'

export const ArchiveList = (): JSX.Element => {
	const { role } = useAuth()

	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [requestsData, setRequestsData] = useState<IInfo[]>()

	const navigate = useNavigate()

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const RequestsData = await RequestsService.getAll({
			IsArchive: true,
		})
		setRequestsData(RequestsData)
		setIsLoad(false)
	}

	const onClick = (item: any) => {
		navigate(getType(item), {
			state: {
				req: item,
			},
		})
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<div className={styles.wrapper}>
			<Title title="Архив заявок" />
			{role === 'Admin' || role === 'Manager' ? (
				<Button
					style={{ width: '200px', marginLeft: 'auto' }}
					onClick={async () => await RequestsService.generateReport(true)}
				>
					Выгрузить в excel
				</Button>
			) : null}
			{requestsData?.length ? (
				<div className={styles.table}>
					<Table
						dataProps={requestsData.map((item) => {
							return {
								...item,
								startDateStr: new Date(item.startDate).toLocaleDateString(),
								endDateStr: new Date(item.endDate).toLocaleDateString(),
							}
						})}
						columns={archiveRequestColumns(onClick)}
					/>
				</div>
			) : (
				<SubHeading title="Данных нет" />
			)}
		</div>
	)
}
