import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'
import { RequestsService } from 'services/Requests.service'

import { Button, SubHeading, Textarea } from '@/components'

import styles from './Modal.module.scss'

export interface ICommentForm {
	renterComment: string
	guardComment: string
}

export const CommentModal: FC<{
	id: string
	comment?: ICommentForm
	close: () => void
}> = ({ id, comment, close }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ICommentForm>({
		mode: 'onChange',
		defaultValues: comment,
	})

	const onSubmit: SubmitHandler<ICommentForm> = async (body) =>
		await RequestsService.setComments(id, body, close)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Добавить комментарий" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<Textarea
					placeholder="Комментарий арендатору"
					{...register('renterComment', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.renterComment}
				/>
				<Textarea
					placeholder="Комментарий охране"
					{...register('guardComment', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.guardComment}
				/>
				<Button>Добавить комментарий</Button>
			</form>
		</div>
	)
}
