import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Loader, Table, Title } from '@/components'

import { RequestsService } from '@/services'

import { getType } from '@/settings'

import { IInfo } from '@/types'

import styles from './List.module.scss'
import { userColumns } from './Table.config'

export const RequestList = (): JSX.Element => {
	const [isLoad, setIsLoad] = useState<boolean>(false)
	const [requestsData, setRequestsData] = useState<IInfo[]>([])

	const navigate = useNavigate()

	useEffect(() => {
		fetch()
	}, [])

	const fetch = async () => {
		setIsLoad(true)
		const RequestsData = await RequestsService.getAll({})
		setRequestsData(RequestsData)
		setIsLoad(false)
	}

	const onClick = (item: any) => {
		navigate(getType(item), {
			state: {
				req: item,
			},
		})
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title title="Заявки" />
			<div className={styles.wrapper}>
				<div className={styles.table}>
					<Table
						dataProps={requestsData.map((item) => {
							return {
								...item,
								startDateStr: new Date(item.startDate).toLocaleDateString(),
								endDateStr: new Date(item.endDate).toLocaleDateString(),
								mainContactName: item.mainContactName
									? item.mainContactName
									: '-',
								mainContactPhoneNumber: item.mainContactPhoneNumber
									? item.mainContactPhoneNumber
									: '-',
								renterComment: item.renterComment ? item.renterComment : '-',
							}
						})}
						columns={userColumns(onClick, fetch)}
					/>
				</div>
			</div>
		</>
	)
}
