export const modalStyle = {
	overlay: {
		backgroundColor: 'rgba(204, 204, 204, 0.8)',
		zIndex: 10,
	},
	content: {
		top: '50%',
		left: '50%',
		border: '1px solid rgb(204, 204, 204)',
		minWidth: '250px',
		width: 'max-content',
		height: 'min-content',
		background: 'rgb(255, 255, 255)',
		borderRadius: '4px',
		outline: 'none',
		padding: '20px',
		transform: 'translate(-50%, -50%)',
	},
}
