import cn from 'clsx'
import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { MdClose } from 'react-icons/md'

import { Button, FieldInput, SubHeading } from '@/components'

import { AuthService } from '@/services'

import styles from './Modal.module.scss'

export const RestorePasswordModal: FC<{
	close: () => void
}> = ({ close }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string }>({
		mode: 'onChange',
	})

	const onSubmit: SubmitHandler<{ email: string }> = async (body) =>
		await AuthService.forgotPassword(body.email, close)

	return (
		<div className={styles.modal}>
			<div className={styles.header}>
				<SubHeading title="Восстановить пароль" />
				<MdClose size={20} onClick={close} />
			</div>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={cn(styles.body, styles.form)}
			>
				<FieldInput
					placeholder="Почта"
					{...register('email', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.email}
					required
				/>
				<Button>Отправить</Button>
			</form>
		</div>
	)
}
