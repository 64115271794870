import {
	Column,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table'
import cn from 'clsx'
import { FC, InputHTMLAttributes, useEffect, useMemo, useState } from 'react'

import { Button } from '@/components'

import styles from './Table.module.scss'

interface IProps {
	dataProps: any[]
	columns: any[]
	className?: string
	colVisibility?: any
	click?: (item: any) => void
}

export const Table: FC<IProps> = ({
	dataProps = [],
	columns,
	className,
	colVisibility,
	click,
}) => {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])

	const table = useReactTable({
		data: dataProps,
		columns,
		state: {
			columnFilters,
			columnVisibility: colVisibility,
		},
		initialState: {
			pagination: {
				pageSize: 20,
			},
		},
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		debugTable: false,
		debugHeaders: false,
		debugColumns: false,
	})

	return (
		<div className="p-2">
			<table className={cn(styles.table, className)}>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder ? null : (
											<>
												<div
													{...{
														className: header.column.getCanSort()
															? 'cursor-pointer select-none'
															: '',
														onClick: header.column.getToggleSortingHandler(),
													}}
												>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
													{{
														asc: ' 🔼',
														desc: ' 🔽',
													}[header.column.getIsSorted() as string] ?? null}
												</div>
												{header.column.getCanFilter() &&
												header.column.id !== 'btns' ? (
													<Filter column={header.column} />
												) : null}
											</>
										)}
									</th>
								)
							})}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr
							key={row.id}
							className={cn({
								'cursor-pointer': click,
							})}
							onClick={() => {
								click && click(row.original)
							}}
						>
							{row.getVisibleCells().map((cell) => (
								<td
									{...{
										style: {
											// width: cell.column.getSize(),
											width: 'auto',
										},
									}}
									key={cell.id}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
			{table.getRowModel().rows.length && table.getPageCount() !== 1 ? (
				<>
					<div className="h-2" />
					<div className={styles.pagination}>
						<Button
							appearance="pagination"
							onClick={() => {
								table.setPageIndex(0)
							}}
							disabled={!table.getCanPreviousPage()}
						>
							{'<<'}
						</Button>
						<Button
							appearance="pagination"
							onClick={() => {
								table.previousPage()
							}}
							disabled={!table.getCanPreviousPage()}
						>
							{'<'}
						</Button>
						<span className="flex items-center gap-1">
							<div>Страница</div>
							<strong>
								{table.getState().pagination.pageIndex + 1} из{' '}
								{table.getPageCount()}
							</strong>
						</span>
						<Button
							appearance="pagination"
							onClick={() => {
								table.nextPage()
							}}
							disabled={!table.getCanNextPage()}
						>
							{'>'}
						</Button>
						<Button
							appearance="pagination"
							onClick={() => {
								table.setPageIndex(table.getPageCount() - 1)
							}}
							disabled={!table.getCanNextPage()}
						>
							{'>>'}
						</Button>
					</div>
				</>
			) : null}
		</div>
	)
}

function Filter({ column }: { column: Column<any, unknown> }) {
	const { filterVariant }: any = column.columnDef.meta ?? {}

	const columnFilterValue = column.getFilterValue()

	const sortedUniqueValues = useMemo(
		() =>
			filterVariant === 'range'
				? []
				: Array.from(column.getFacetedUniqueValues().keys()).sort(),
		[column.getFacetedUniqueValues(), filterVariant] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return filterVariant === 'select' ? (
		<select
			onChange={(e) => column.setFilterValue(e.target.value)}
			className="w-56 h-[38px] rounded border"
			value={columnFilterValue?.toString()}
		>
			<option value="">Все</option>
			{sortedUniqueValues.map((value) => (
				<option value={value} key={value}>
					{value}
				</option>
			))}
		</select>
	) : (
		<>
			<DebouncedInput
				type="text"
				value={(columnFilterValue ?? '') as string}
				onChange={(value) => column.setFilterValue(value)}
				placeholder={`Поиск...`}
				className="w-36 border shadow rounded"
				list={column.id + 'list'}
			/>
			<div className="h-1" />
		</>
	)
}

function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	value: string | number
	onChange: (value: string | number) => void
	debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
	const [value, setValue] = useState(initialValue)

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value)
		}, debounce)

		return () => clearTimeout(timeout)
	}, [value]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<input
			{...props}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	)
}
