import { AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { toast } from 'react-toastify'

import { axiosPrivate } from '../settings/axios'
import { IAuthBody, IAuthRes } from '../types/Auth.type'
import { jwtInterface } from '../types/Jwt.type'

const getAuthUrl = (url: string) => `/auth/${url}`

export const AuthService = {
	async auth(
		{ email, password }: IAuthBody,
		setError: (name: string, option: { message: string }) => void
	): Promise<AxiosResponse> {
		try {
			const res = await axiosPrivate.post<IAuthRes>(getAuthUrl('login'), {
				email,
				password,
			})

			if (res.data.token) {
				const decToken: jwtInterface = jwtDecode(res.data.token)

				Cookies.set('token', res.data.token)
				Cookies.set('refreshToken', res.data.refreshToken)
				Cookies.set('expiration', res.data.expiration)
				Cookies.set('role', decToken.Role)

				window.location.reload()
			}

			return res
		} catch (e: any) {
			toast.error(e.response.data.email)
			setError('email', { message: e.response.data.email })
			setError('password', { message: e.response.data.email })
		}
	},

	async refreshToken() {
		try {
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async forgotPassword(email: string, close: () => void): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAuthUrl('forgot_password'), { email })
			toast.success('Запрос на смену пароля отправлен')
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},

	async resetPassword(
		body: { email: string; newPassword: string },
		close: () => void,
		update: () => void
	): Promise<void> {
		try {
			await axiosPrivate.post<any>(getAuthUrl('reset_password'), body)
			toast.success('Данные изменены')
			update()
			close()
		} catch (e: any) {
			toast.error(e.message)
			return e.response
		}
	},
}
