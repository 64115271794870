import cn from 'clsx'
import { InputHTMLAttributes, forwardRef, memo } from 'react'
import { FieldError } from 'react-hook-form'

import { Input } from '@/components'

import styles from './Field.module.scss'

export interface IFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	placeholder: string
	error?: FieldError
	required?: boolean
}

export const FieldInput = memo(
	forwardRef<HTMLInputElement, IFieldProps>(
		({ placeholder, error, type = 'text', style, required, ...rest }, ref) => {
			return (
				<div
					className={cn(styles.common, styles.field, {
						[styles.error]: error,
					})}
					style={style}
				>
					<label>
						<span
							className={cn({
								[styles.required]: required,
							})}
						>
							{placeholder}
						</span>
						<Input ref={ref} type={type} {...rest} />
						{error && <p>{error.message}</p>}
					</label>
				</div>
			)
		}
	)
)

FieldInput.displayName = 'Input'
