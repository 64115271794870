import { PropsWithChildren, createContext, useState } from 'react'

export interface AuthContextInterface {
	token: string
	role: string
	refreshToken: string
	setToken?: (token: string) => void
	serRefreshToken?: (token: string) => void
	setRole?: (role: string) => void
}

export const AuthContext = createContext<AuthContextInterface>({
	token: '',
	role: '',
	refreshToken: '',
})

const AuthProvider = ({
	children,
}: PropsWithChildren<AuthContextInterface>) => {
	const [token, setToken] = useState('')
	const [refreshToken, serRefreshToken] = useState('')
	const [role, setRole] = useState<string>('')

	return (
		<AuthContext.Provider
			value={{ token, role, refreshToken, setRole, setToken, serRefreshToken }}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthProvider
