import { useAuth } from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useLocation, useParams } from 'react-router-dom'

import { Button, Loader, SubHeading, Table, Title } from '@/components'

import { EmployeesService, UserService } from '@/services'

import { modalStyle } from '@/settings'

import { IEmployeeUser } from '@/types'

import styles from './Employees.module.scss'
import { ChoiceModal, EmployeesModal } from './Modal'
import { employeesColumns } from './table.config'

export interface IForm {
	name: string
	surname: string
	lastName: string
	position: string
	phoneNumber: string
}

export type ModalType = 'edit' | 'add' | 'control'

export const Employees = (): JSX.Element => {
	const { role } = useAuth()
	const { state } = useLocation()
	const { shopId } = useParams()

	const [employees, setEmployees] = useState<IEmployeeUser[]>([])
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
	const [type, setType] = useState<ModalType>()
	const [isLoad, stIsLoad] = useState<boolean>(true)
	const [employeeProps, setEmployeeProps] = useState<IEmployeeUser>()

	useEffect(() => {
		fetch()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const fetch = async () => {
		stIsLoad(true)
		if (role === 'User') {
			const employees = await EmployeesService.getUser()
			setEmployees(employees)
		} else {
			const data = await UserService.getUserCounterpartyView({
				CounterpartyId: shopId,
			})
			setEmployees(data?.employees)
		}
		stIsLoad(false)
	}

	function openModal(type: ModalType, employee?: IEmployeeUser) {
		setType(type)
		if (type === 'control') setEmployeeProps(employee)
		setModalIsOpen(true)
	}

	function closeModal() {
		setModalIsOpen(false)
		setEmployeeProps(null)
	}

	const ModalComponent = () => {
		switch (type) {
			case 'control':
				return (
					<ChoiceModal
						open={openModal}
						close={closeModal}
						employee={employeeProps}
						role={role}
						update={fetch}
					/>
				)
			case 'edit':
				return (
					<EmployeesModal
						close={closeModal}
						fields={employeeProps}
						update={fetch}
						title="Редактирование"
						type={type}
					/>
				)
			case 'add':
				return (
					<EmployeesModal
						close={closeModal}
						fields={employeeProps}
						update={fetch}
						title="Добавление"
						type={type}
					/>
				)
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={shopId ? `Сотрудники магазина ${state.shopName}` : 'Сотрудники'}
			/>
			<div className={styles.wrapper}>
				{role === 'User' && (
					<Button
						className={styles.addBtn}
						onClick={() => {
							openModal('add')
						}}
					>
						Добавить
					</Button>
				)}
				{employees?.length ? (
					<div className={styles.table}>
						<Table
							dataProps={employees.map((item) => {
								return {
									...item,
									passageStatus: item.passageStatus ? item.passageStatus : '-',
								}
							})}
							colVisibility={{
								btns: role !== 'Guard',
							}}
							columns={employeesColumns(openModal)}
						/>
					</div>
				) : (
					<SubHeading title="Данных нет" />
				)}
			</div>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				ariaHideApp={false}
				style={modalStyle}
			>
				<ModalComponent />
			</Modal>
		</>
	)
}
