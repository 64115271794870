import cn from 'clsx'
import { forwardRef, memo } from 'react'

import styles from './Button.module.scss'
import { IButton } from './Button.props'

export const Button = memo(
	forwardRef<HTMLButtonElement, IButton>(
		({ children, className, appearance = 'white', ...rest }, ref) => {
			return (
				<button
					className={cn(styles.button, className, {
						[styles.white]: appearance === 'white',
						[styles.red]: appearance === 'red',
						[styles.pagination]: appearance === 'pagination',
						[styles.yellow]: appearance === 'yellow',
					})}
					ref={ref}
					{...rest}
				>
					{children}
				</button>
			)
		}
	)
)

Button.displayName = 'button'
