import { addDays, format } from 'date-fns'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import {
	Button,
	FieldInput,
	FieldSelect,
	Loader,
	SubHeading,
	Title,
} from '@/components'

import { useAuth } from '@/hooks'

import {
	EmployeesService,
	PermitsService,
	RequestsService,
	UserService,
} from '@/services'

import { isDisable, modalStyle } from '@/settings'

import { IAddPassage, IInfo, IPermit, ISelect } from '@/types'

import styles from './Form.module.scss'
import { CommentModal } from './modal/CommentModal'

export type IAddPassageForm = IAddPassage & {
	counterpartyId?: string
}

export const RequestNewPass = (): JSX.Element => {
	const navigate = useNavigate()
	const { role } = useAuth()
	const { id } = useParams()
	const { state } = useLocation()

	const [isLoad, setIsLoad] = useState<boolean>(true)
	const [modalIsOpen, setIsOpen] = useState<boolean>(false)

	const [requestInfo, setRequestInfo] = useState<IInfo & IPermit>()
	const [counterparty, setCounterparty] = useState<ISelect[]>()
	const [users, setUsers] = useState<ISelect[]>([])

	const fetch = async (id?: string) => {
		setIsLoad(true)
		if (role === 'User') {
			const users = await EmployeesService.getUserBySelect()
			setUsers(users)
		} else if (role === 'Admin' || role === 'Manager') {
			const data = await UserService.getUserAll()
			setCounterparty(
				data
					.filter((item) => item.counterparty !== null)
					.map((item) => {
						return {
							label: item.counterparty.name,
							value: item.counterparty.id,
						}
					})
			)
		} else {
			const users = await UserService.getUserCounterpartyView({ UserId: id })
			setUsers(
				users?.employees
					?.filter((item) => !isPassStatus(item))
					?.map((item: any) => {
						return {
							label: item.name ? item.name : item.userName,
							value: item.id,
						}
					})
			)
		}
		setIsLoad(false)
	}

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isDirty, isValid },
	} = useForm<IAddPassageForm>({
		mode: 'onChange',
		defaultValues: async () => {
			if (id) {
				const data = await RequestsService.getInfo(id, false)
				setRequestInfo(data)

				!isDisable(state?.req?.status, role)
					? fetch(data?.requestCreator?.id)
					: setIsLoad(false)

				return {
					...data,
					employeeId: {
						label: data?.employee?.name,
						value: data?.employee?.id,
					},
					counterpartyId: {
						label: data?.counterparty?.name,
						value: data?.counterparty?.id,
					},
					comment: {
						guardComment: data?.guardComment,
						renterComment: data?.renterComment,
					},
					check: true,
				}
			} else if (state?.user) {
				fetch()

				return {
					employeeId: {
						label: state?.user?.name,
						value: state?.user?.id,
					},
				}
			} else {
				fetch()
			}
		},
	})

	const startDateWatch = watch('startDate')
	const counterpartyIdWatch = watch('counterpartyId')
	const guardCommentWatch = watch('guardComment')
	const renterCommentWatch = watch('renterComment')

	const onSubmit: SubmitHandler<any> = async (body) => {
		if (id) {
			await RequestsService.editPassage(
				{
					sideCounterparty: body.sideCounterparty ? body.sideCounterparty : '',
					startDate: body.startDate,
					endDate: body.endDate,
					employeeId: body.employeeId.value,
					id: id,
				},
				navigate,
				role
			)
		} else {
			if (role === 'User')
				await RequestsService.addPassage(
					{
						...body,
						employeeId: body.employeeId.value,
					},
					navigate,
					role
				)
			else
				await RequestsService.addPassage(
					{
						...body,
						employeeId: body.employeeId.value,
					},
					navigate,
					role,
					body.counterpartyId.value
				)
		}
	}

	if (isLoad) {
		return <Loader />
	}

	return (
		<>
			<Title
				title={
					id
						? 'Заявка на пропуск сотрудника'
						: 'Новая заявка на пропуск сотрудника'
				}
			/>
			<form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
				{state?.req?.status?.name === 'Принято' ? (
					<SubHeading title="Заявка принята" className="text-green-500" />
				) : null}
				{state?.req?.status?.name === 'Аннулировано' ? (
					<SubHeading title="Заявка аннулирована" className="text-red-500" />
				) : null}
				<FieldInput
					type="date"
					placeholder="Дата начала действия заявки"
					min={format(
						startDateWatch ? new Date(startDateWatch) : new Date(),
						'yyyy-MM-dd'
					)}
					max={format(
						startDateWatch ? new Date(startDateWatch) : addDays(new Date(), 37),
						'yyyy-MM-dd'
					)}
					disabled={isDisable(state?.req?.status, role)}
					{...register('startDate', {
						required: 'Поле обязательно к заполнению!',
					})}
					error={errors.startDate}
					required
				/>
				{startDateWatch && (
					<FieldInput
						type="date"
						placeholder="Дата завершения действия заявки"
						min={format(new Date(startDateWatch), 'yyyy-MM-dd')}
						max={format(addDays(new Date(startDateWatch), 14), 'yyyy-MM-dd')}
						disabled={isDisable(state?.req?.status, role)}
						{...register('endDate', {
							required: 'Поле обязательно к заполнению!',
						})}
						error={errors.endDate}
						required
					/>
				)}
				{role === 'Admin' || role === 'Manager' ? (
					<>
						<FieldSelect
							id="counterpartyId"
							placeholder="Магазин"
							control={control}
							rules={{
								required: 'Поле обязательно к заполнению!',
							}}
							error={errors.counterpartyId}
							options={counterparty}
							changeProps={async (value) => {
								const data = await UserService.getUserCounterpartyView({
									CounterpartyId: value.value.toString(),
								})
								setUsers(
									data?.employees
										.filter((item) => !isPassStatus(item))
										.map((item) => {
											return {
												label: item.name,
												value: item.id,
											}
										})
								)
							}}
							disabled={state?.req}
							required
						/>
						{counterpartyIdWatch && (
							<FieldSelect
								id="employeeId"
								placeholder="ФИО сотрудника"
								control={control}
								rules={{
									required: 'Поле обязательно к заполнению!',
								}}
								options={users}
								error={errors.employeeId}
								disabled={isDisable(state?.req?.status, role)}
								required
							/>
						)}
					</>
				) : (
					<FieldSelect
						id="employeeId"
						placeholder="ФИО сотрудника"
						control={control}
						rules={{
							required: 'Поле обязательно к заполнению!',
						}}
						options={users}
						error={errors.employeeId}
						disabled={isDisable(state?.req?.status, role)}
						required
					/>
				)}
				{(role === 'Manager' || role === 'Admin' || role === 'Guard') &&
				state?.req?.phoneEmergency ? (
					<div>
						<p>
							Номер телефона ответственного за ЧС:{' '}
							<Link to={`tel:${state?.req?.phoneEmergency}`}>
								{state?.req?.phoneEmergency}
							</Link>
						</p>
					</div>
				) : null}
				{role === 'User'
					? renterCommentWatch && (
							<p>Комментарий для арендатора: {renterCommentWatch}</p>
						)
					: role === 'Admin' || role === 'Manager'
						? (guardCommentWatch || renterCommentWatch) && (
								<div>
									{guardCommentWatch && (
										<p>Комментарий для охраны: {guardCommentWatch}</p>
									)}
									{renterCommentWatch && (
										<p>Комментарий для арендатора: {renterCommentWatch}</p>
									)}
								</div>
							)
						: role === 'Guard' &&
							guardCommentWatch && (
								<p>Комментарий для охраны: {guardCommentWatch}</p>
							)}
				{state?.req ? (
					role === 'User' ? (
						(state?.req?.status?.name === 'На рассмотрении' ?? (
							<Button>Изменить заявку</Button>
						))
					) : role === 'Admin' || role === 'Manager' ? (
						<div className={styles.btns}>
							{!isDisable(state?.req?.status, role) ? (
								<Button>Изменить заявку</Button>
							) : (
								<>
									{requestInfo.status.name === 'Аннулировано' ? null : (
										<Button
											appearance="red"
											onClick={async (e) => {
												e.preventDefault()
												await RequestsService.decline(id, () => navigate(-1))
											}}
										>
											Аннулировать
										</Button>
									)}
									{requestInfo.isPermitCreated ? null : (
										<Button
											onClick={async (e) => {
												e.preventDefault()
												await PermitsService.add(
													{
														employeeId: requestInfo.employee.id,
														requestId: requestInfo.id,
													},
													() => navigate(-1)
												)
											}}
											appearance="yellow"
										>
											Выдать пропуск
										</Button>
									)}
								</>
							)}
							<Button
								onClick={(e) => {
									e.preventDefault()
									setIsOpen(true)
								}}
							>
								Добавить комментарий
							</Button>
						</div>
					) : null
				) : (
					<Button disabled={!isDirty || !isValid}>Отправить заявку</Button>
				)}
			</form>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => setIsOpen(false)}
				ariaHideApp={false}
				style={modalStyle}
			>
				<CommentModal
					id={id}
					comment={{
						renterComment: renterCommentWatch,
						guardComment: guardCommentWatch,
					}}
					close={() => setIsOpen(false)}
				/>
			</Modal>
		</>
	)
}

export const isPassStatus = (item: any) => {
	switch (item?.passageStatus) {
		case 'Временный пропуск получен':
			return true
		case 'Заявка на рассмотрении':
			return true
		case 'Заявка не направлена':
			return false
		case 'Временный пропуск просрочен/отменён':
			return true
		default:
			return true
	}
}
